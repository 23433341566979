import React, { useEffect, useState, PropsWithChildren, ReactElement, VFC } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { useTabStates, useUiStates } from '../../hooks/reduxHooks';

export const TabState = React.createContext<{ tab: string | undefined; initialized: boolean } | undefined>(undefined);

interface StatefulTabsProps {
	onSelect?: (newTab: string) => void;
	retainPageState?: boolean;
	transition?: boolean;
}
const StatefulTabs: VFC<PropsWithChildren<StatefulTabsProps>> = (
	props: PropsWithChildren<StatefulTabsProps>,
): ReactElement => {
	const { children, onSelect, retainPageState } = props;
	const [initialized, setInitialized] = useState<boolean>(false);
	const uiStates = useUiStates(true);
	const tabStates = useTabStates();
	const history = useHistory();
	const location = useLocation();

	const getMatchedTab = (tab: string | null) =>
		tab != null &&
		React.Children.toArray(children).find(
			(child) =>
				child &&
				typeof child === 'object' &&
				'type' in child &&
				child.type === Tab &&
				child.props.eventKey === tab,
		);

	const getFirstTab = () => {
		if (retainPageState) {
			// check query string
			const searchParams = new URLSearchParams(location.search);
			if (searchParams.has('tab')) {
				const matchedTab = getMatchedTab(searchParams.get('tab'));
				if (matchedTab && typeof matchedTab === 'object' && 'props' in matchedTab) {
					return matchedTab.props.eventKey;
				}
			}

			// check ui states
			if (tabStates) {
				const storedTabState = tabStates.get();
				if (storedTabState) {
					const matchedTab = getMatchedTab(storedTabState);
					if (matchedTab && typeof matchedTab === 'object' && 'props' in matchedTab) {
						return matchedTab.props.eventKey;
					}
				}
			}
		}
		const firstTab = React.Children.toArray(children).find(
			(child) => child && typeof child === 'object' && 'type' in child && child.type === Tab,
		);
		if (firstTab && typeof firstTab === 'object' && 'props' in firstTab) {
			return firstTab.props.eventKey;
		}
	};

	const [selectedTab, setSelectedTab] = useState<string>(getFirstTab());

	const changeTab = (newTab: string) => {
		if (retainPageState) {
			tabStates && tabStates.set(newTab);
			
			const nextState:Record<string, any> = uiStates.get(location.pathname + '?tab=' + newTab);
			const nextSearch = new URLSearchParams({ tab: newTab, ...nextState });

			history.replace(location.pathname + '?' + nextSearch.toString());
		}
		onSelect && onSelect(newTab);
		setSelectedTab(newTab);
	};

	useEffect(() => {
		const firstTab = getFirstTab();
		onSelect && onSelect(firstTab);
		setSelectedTab(firstTab);

		if (retainPageState) {
			// if there is a search string, but tab is the only key, transfer uiStates to the search
			const currentSearch = new URLSearchParams(location.search);
			const keys: string[] = [];
			currentSearch.forEach((value, key) => {
				keys.push(key);
			});

			if (keys.length < 1 || (keys.length === 1 && keys.includes('tab'))) {
				// if no tab field in the query string, add one and grab the 
				// current state for that tab and add it there 
				const currentState = uiStates.get(location.pathname + '?tab=' + firstTab);
				const nextSearch = new URLSearchParams({ tab: firstTab, ...currentState });

				history.replace(location.pathname + '?' + nextSearch.toString());
			} else {
				// otherwise is there is a query string but it does not have a 
				// tab field, add it to the query string
				currentSearch.set('tab', firstTab);
				history.replace(location.pathname + '?' + currentSearch.toString());
			}
		}

		setInitialized(true);
	}, []);

	const nextProps = { ...props };
	delete nextProps.retainPageState;

	return (
		<TabState.Provider value={{ tab: selectedTab, initialized }}>
			<Tabs
				{...nextProps}
				activeKey={selectedTab}
				onSelect={(newKey) => newKey != null && changeTab(newKey)}
				transition={props.transition || false}
			/>
		</TabState.Provider>
	);
};

export default StatefulTabs;
