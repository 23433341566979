import React, { Suspense, VFC } from 'react';
import { Button } from 'react-bootstrap';

const DownloadButton = React.lazy(() => import('./views/DownloadButton'));

interface ProFormaInvoiceProps {
	disabled?: boolean;
	companyData?: {
		company_name: string;
		address_1: string;
		address_2: string | null;
		city: string;
		region: string;
		postal_code: string;
		country_name: string;
		ebs_account_number: string;
	};
	invoiceDate: string | number | Date;
	orderNumber: string;
	poNumber: string;
	orderDetails: {
		itemName: string;
		unit_price: number | string;
		publisher_part_number?: number | string;
		nsuid?: number | string;
		quantity: number | null;
	}[];
	isDigitalOrder?: boolean;
	subtotal?: string;
	taxable?: string | number;
	tax?: string | number;
	taxAmount?: string | number;
	grandTotal?: string | number;
	fileName?: string | number;
	itemFields?: string[];
}
const ProFormaInvoice: VFC<ProFormaInvoiceProps> = ({
	disabled,
	companyData = {},
	invoiceDate,
	orderNumber,
	poNumber,
	orderDetails,
	isDigitalOrder,
	subtotal,
	taxable,
	tax,
	taxAmount,
	grandTotal,
	fileName,
	itemFields = [],
}) => {
	const fallbackButton = <Button variant="outline-secondary" disabled>
		Download Pro Forma Invoice
	</Button>;
	return disabled ? (
		<div>{fallbackButton}</div>
	) : (
		<div>
			<Suspense fallback={fallbackButton}>
				<DownloadButton
					fileName={fileName}
					companyData={companyData}
					invoiceDate={invoiceDate}
					orderNumber={orderNumber}
					poNumber={poNumber}
					orderDetails={orderDetails}
					isDigitalOrder={isDigitalOrder}
					grandTotal={grandTotal}
					subtotal={subtotal}
					taxable={taxable}
					tax={tax}
					taxAmount={taxAmount}
					itemFields={itemFields}
				/>
			</Suspense>
		</div>
	);
};

export default ProFormaInvoice;
