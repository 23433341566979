import React, { Fragment, VFC } from 'react';
import { useQuery } from 'react-query';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { getProductGameFeatures } from '../../../services/productsService';

interface GameFeaturesProps {
	productId: number;
}
const GameFeatures: VFC<GameFeaturesProps> = ({ productId }) => {
	const featuresQuery = useQuery(
		['getProductGameFeatures', productId],
		() => getProductGameFeatures(String(productId)),
		{ enabled: !!productId, refetchOnMount: false },
	);
	const featuresData = featuresQuery.data?.data;

	return <>
		{featuresData?.map(({ category, fields }, index) => {
			return (
				<Fragment key={index}>
					<SectionTitle>{category}</SectionTitle>
					<div>{fields.map(({ label, value }, index) => (
						<PropertyDisplay label={label} key={index}>{value}</PropertyDisplay>
					))}</div>
				</Fragment>
			);
		})}
	</>;
};

export default GameFeatures;
