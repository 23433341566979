import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../../components/Page/Page';
import StatusText from '../../../components/StatusText/StatusText';
import Title from '../../../components/Title/Title';
import { loadingConstants } from '../../../constants/loadingConstants';
import { permConst } from '../../../constants/permConst';
import { roleConstants } from '../../../constants/roleConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { getProductRightsTransferRequests } from '../../../services/productTransfersService';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { displayString } from '../../../utils/stringUtils';
import { isFeatureSunsetted } from '../../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import EnterOwnershipTransferCodeModal from './modals/EnterOwnershipTransferCodeModal';
import RescindOwnershipTransferModal from './modals/RescindOwnershipTransferModal';


const title = 'Transfer Ownership';

function mapStateToProps(state, prop) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED,
		sunsetFeatures: state.referenceReducer.sunsetFeatures?.content,
	};
}

export function getDistribution(value) {
	if (value.digital_pub_flag && value.retail_pub_flag) {
		return 'Both';
	}
	if (value.digital_pub_flag) {
		return 'Digital';
	}
	return value.retail_pub_flag ? 'Retail' : '';
}

function displayStatusExternalUser(status) {
	switch (status) {
		case transferRightsConstants.REQUEST_STATUS.REVIEWED:
			return transferRightsConstants.REQUEST_STATUS.PENDING;
		default:
			return status;
	}
}

export class ProductOwnershipTransferTable extends Component {
	constructor(props) {
		super(props);

		const isOrderReviewer = props.userProfile.roles.some(
			(role) => role.name === roleConstants.ORDER_REVIEW
		);
		const isPurchaseOrderApprover = props.userProfile.roles.some(
			(role) => role.name === roleConstants.PURCHASE_ORDER_APPROVER
		);

		this.state = {
			ownershipTransfers: '',
			filterProperties: this.filters,
			isLoading: true,
			isOrderReviewer,
			isPurchaseOrderApprover,
			modalType: null,
			selectedTransferId: null,
			showModal: false
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.loadResources = this.loadResources.bind(this);
		this.updateTransferStatus = this.updateTransferStatus.bind(this);
		this.addRightsRequest = this.addRightsRequest.bind(this);
		this.addPlatformData = this.addPlatformData.bind(this);
	}

	filters = new Map([
		[
			'Platform',
			{
				filter: 'product_system_name',
				selectableFilters: new Set()
			}
		],
		[
			'Status',
			{
				filter: 'transfer_status',
				selectedFilters: new Set(),
				selectableFilters: new Set(),
				customEvaluation: (status, filters) => {
					const tempFilters = new Set(filters);
					if (tempFilters.has('Rejected')) {
						tempFilters.add(displayString(transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER));
						tempFilters.add(displayString(transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER));
					}
					return tempFilters.has(displayString(status));
				}
			}
		]
	]);

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		if (!this.props.platformsReady) {
			return;
		}

		this.loadResources();
		this.componentDidUpdate = null;
	}

	isSunsetted() {
		const { sunsetFeatures } = this.props;
		return isFeatureSunsetted(sunsetFeatures, 'ownership_transfers');
	}

	loadResources() {
		this.getPlatforms();
		this.getStatus();
		this.getOwnershipTransfers();
	}

	updateTransferStatus(status, id) {
		const { ownershipTransfers } = this.state;
		for (let request of ownershipTransfers) {
			if (request.product_transfer_id === id) {
				request.transfer_status = status;
				break;
			}
		}
		this.setState({
			ownershipTransfers: ownershipTransfers
		});
	}

	addPlatformData(transferRight) {
		const { platforms } = this.props;
		const platformData = platforms.find(p => p.platform_code === transferRight.product_platform_code);
		return {
			...transferRight,
			product_platform_name: platformData.platform_name,
			product_system_name: platformData.system_name,
		};
	}

	addRightsRequest(transferRequest) {
		const { ownershipTransfers } = this.state;
		ownershipTransfers.push(this.addPlatformData(transferRequest));
		this.setState({
			ownershipTransfers: [...ownershipTransfers]
		});
	}

	getOwnershipTransfers() {
		const { userProfile } = this.props;
		let ownershipTransfers = null;
		const transfer_type = 'OWNERSHIP';

		getProductRightsTransferRequests(transfer_type)
			.then((response) => {
				ownershipTransfers = response.data;

				const canViewAllRightTransfers = isAuthorized(
					userProfile.permissions,
					[permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_OWNERSHIP]
				);

				if (!canViewAllRightTransfers) {
					ownershipTransfers.forEach((transferRight) => {
						if (
							transferRight.transfer_status ===
							transferRightsConstants.REQUEST_STATUS.REVIEWED
						) {
							transferRight.transfer_status =
								transferRightsConstants.REQUEST_STATUS.PENDING;
						} else if (
							transferRight.transfer_status ===
								transferRightsConstants.REQUEST_STATUS
									.REJECTED_BY_APPROVER ||
							transferRight.transfer_status ===
								transferRightsConstants.REQUEST_STATUS
									.REJECTED_BY_REVIEWER
						) {
							transferRight.transfer_status = 'Rejected';
						}
					});
				}

				ownershipTransfers = ownershipTransfers.map(transferRight => {
					return this.addPlatformData(transferRight);
				});
				this.setState({
					ownershipTransfers: ownershipTransfers,
					isLoading: false
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading publishers data'),
					'error',
					error
				);
			});
	}

	isExternalUser() {
		const { userProfile } = this.props;

		return isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY
		]);
	}

	getTableStructure() {
		const { userProfile } = this.props;
		const canViewAllRightTransfers = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_OWNERSHIP
		]);

		let tableConfiguration = [
			{
				Header: 'Request Date',
				accessor: 'creation_date',
				minWidth: 110,
				Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE)
			},
			{
				Header: 'Title',
				accessor: 'product_game_name'
			},
			{
				Header: 'Platform',
				accessor: 'product_platform_name'
			},
			{
				Header: 'Game Code',
				accessor: 'product_game_code'
			},
			{
				Header: 'Initiating',
				accessor: 'transfer_from_company'
			},
			{
				Header: 'Receiving',
				accessor: 'transfer_to_company'
			},
			{
				Header: 'Transfer Date',
				accessor: 'transfer_date',
				minWidth: 110,
				Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE)
			},
			{
				Header: 'Transfer Code',
				accessor: 'transfer_token'
			},
			{
				Header: 'Request Status',
				accessor: 'transfer_status',
				Cell: (cell) => {
					let variant =
						cell.original.transfer_status === 'PROCESSED'
							? 'success'
							: 'secondary';
					const regex = new RegExp(
						`Rejected|${transferRightsConstants.REQUEST_STATUS.RESCINDED}|${transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER}|${transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER}`,
					);
					variant = regex.test(cell.original.transfer_status)
						? 'danger'
						: variant;

					return (
						<StatusText variant={variant}>
							{canViewAllRightTransfers
								? cell.value
								: displayStatusExternalUser(cell.value).toUpperCase()}
						</StatusText>
					);
				},
			},
			{
				...actionsColumn,
				Cell: ({ original }) => {
					if (canViewAllRightTransfers) {
						return (
							<Link to={`/admin/product-ownership-transfer-requests/${original.product_transfer_id}`}>
								<FAIcon name="chevron-right" />
							</Link>
						);
					}
					const displayEllipses =
						original.transfer_from_company ===
						userProfile.companyName;
					return displayEllipses && this.generateOptions(original);
				}
			}
		];

		return tableConfiguration;
	}

	generateOptions(row) {
		const displayRescindRequest =
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS
					.AWAITING_RECEIVING_PUBLISHER ||
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING ||
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;

		return (
			<MeatballDropdown>
				{displayRescindRequest && !this.isSunsetted() && (
					<MeatballDropdown.Item
						className='text-danger'
						onClick={() =>
							this.toggleModal(
								'rescindModal',
								row.product_transfer_id,
							)
						}
					>
						Rescind Request
					</MeatballDropdown.Item>
				)}
			</MeatballDropdown>
		);
	}

	renderModal() {
		const { userProfile, platforms } = this.props;
		switch (this.state.modalType) {
			case 'rescindModal':
				return (
					<RescindOwnershipTransferModal
						userProfile={userProfile}
						toggleModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
						transferId={this.state.selectedTransferId}
						getDistributionRightsRequests={
							this.getDistributionRightsRequests
						}
					/>
				);
			case 'enter-assignment-code-modal':
				return (
					<EnterOwnershipTransferCodeModal
						closeModal={this.toggleModal}
						userProfile={userProfile}
						addRightsRequest={this.addRightsRequest}
						platforms={platforms}
					/>
				);
			default:
				toasterNotify(
					`An unexpected error occurred opening modal ${this.state.modalType}`
				);
		}
	}

	toggleModal(type, transferId = null, loadResources = false) {
		if (this.state.showModal === true) {
			type = null;
		}
		this.setState(
			{
				showModal: !this.state.showModal,
				modalType: type,
				selectedTransferId: transferId
			},
			() => {
				if (loadResources) {
					this.loadResources();
				}
			}
		);
	}

	getPlatforms() {
		const { platforms } = this.props;
		const { filterProperties } = this.state;
		const filterItems = [];
		platforms.forEach((p) => {
			if (!this.isSunsetted() || p.platform_name !== 'Wii') {
				filterItems.push(p.platform_name);
			}
		});

		if (filterItems.length >= 2) {
			filterItems.forEach((name) => filterProperties.get('Platform').selectableFilters.add(name));
		} else {
			filterProperties.delete('Platform');
		}
	}

	getStatus() {
		const {
			filterProperties,
			isOrderReviewer,
			isPurchaseOrderApprover
		} = this.state;

		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(
					transferRightsConstants.REQUEST_STATUS
						.AWAITING_RECEIVING_PUBLISHER
				)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.PENDING)
			);

		if (isOrderReviewer || isPurchaseOrderApprover) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS.REVIEWED
					)
				);
		}

		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.PROCESSED)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.RESCINDED)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.REVOKED)
			);

		if (isOrderReviewer || isPurchaseOrderApprover) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS
							.REJECTED_BY_REVIEWER
					)
				);
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS
							.REJECTED_BY_APPROVER
					)
				);
		}

		if (!isOrderReviewer && !isPurchaseOrderApprover) {
			filterProperties.get('Status').selectableFilters.add('Rejected');
		}

		// configure default filters
		if (this.isSunsetted()) {
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS.PROCESSED
					)
				);
		} else {
			if (isOrderReviewer) {
				filterProperties
					.get('Status')
					.selectedFilters.add(
						displayString(
							transferRightsConstants.REQUEST_STATUS.REVIEWED
						)
					);
			}
			if (isPurchaseOrderApprover) {
				filterProperties
					.get('Status')
					.selectedFilters.add(
						displayString(
							transferRightsConstants.REQUEST_STATUS.PENDING
						)
					);
			}
		}


	}

	titleButton() {
		return (
			this.isExternalUser() && !this.isSunsetted() && (
				<Button
					variant="primary"
					onClick={() =>
						this.toggleModal('enter-assignment-code-modal')
					}
				>
					<FAIcon name="plus" className="mr-1" />
					Enter Assignment Code
				</Button>
			)
		);
	}

	render() {
		const { ownershipTransfers, isLoading } = this.state;
		const dataFormat = this.getTableStructure();

		return (
			<Page>
				<Title title={title} button={this.titleButton()} />
				{isLoading ? (
					<Loading />
				) : (
					<FilterableTable
						className="product-ownership-transfer-requests-table"
						data={ownershipTransfers}
						dataFormat={dataFormat}
						filterProperties={this.state.filterProperties}
						searchableFields={[
							'product_game_name',
							'transfer_from_company',
							'transfer_to_company',
							'product_game_code'
						]}
						searchableFieldPlaceHolder={
							'Search by Title, Game Code, or Publisher...'
						}
						defaultSorted={[
							{
								id: 'creation_date',
								desc: true
							}
						]}
					/>
				)}
				{this.state.showModal && this.state.modalType
					? this.renderModal()
					: null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ProductOwnershipTransferTable);
