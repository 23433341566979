import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import NCLRestrictedPlatformsAlert from '../../components/alerts/NCLRestrictedPlatformsAlert/NCLRestrictedPlatformsAlert';
import { linkColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Page from '../../components/Page/Page';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { loadingConstants } from '../../constants/loadingConstants';
import { permConst } from '../../constants/permConst';
import { connectQueryData, useCompanyAgreementsQuery } from '../../hooks/queryHooks';
import { getProducts } from '../../services/productsService';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { generateOffsetBatches, paginationLimits } from '../../utils/paginationUtil';
import { displayString } from '../../utils/stringUtils';
import { getSunsetMessage, isSunsettingFeatureStillValid } from '../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import CreateProductModal from './modals/CreateProductModal';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED,
		sunsetFeatures: state.referenceReducer.sunsetFeatures,
		sunsetFeaturesReady: state.referenceReducer.sunsetFeatures?.meta.status === loadingConstants.COMPLETED
	};
}

class ProductManagement extends Component {
	constructor(props) {
		super(props);
		const title = 'Products';
		let dataFormat = this.getTableFormat();
		let filterProperties = new Map();
		if (this.canViewAllPlatforms()) {
			filterProperties.set('Platform', {
				filter: 'platform_code',
				selectableFilters: new Set(),
				customEvaluation: (platformCode, filters) => {
					const { platforms } =  this.props;
					const platformName = platforms.find((p) => p.platform_code === platformCode)?.platform_name;
					return filters.has(platformName);
				}
			});
		}
		if (this.canSeeReleaseDate()) {
			filterProperties.set('Release Date', {
				filter: 'release_date',
				selectableFilters: new Set(),
				customEvaluation: (releaseDate, filters) => {
					let userHasAttribute = false;
					const thisMonthBegin = dayjs().startOf('month');
					const compareDate = dayjs(releaseDate);
					const futureMonthEnd = (x) => {
						return dayjs()
							.add(x, 'month')
							.endOf('month')
							.format();
					};

					filters.forEach((filter) => {
						switch (filter) {
							case 'Current Month':
								userHasAttribute =
									userHasAttribute ||
									compareDate.isBetween(
										thisMonthBegin,
										futureMonthEnd('0')
									);
								break;
							case 'Next Month':
								userHasAttribute =
									userHasAttribute ||
									compareDate.isBetween(
										thisMonthBegin,
										futureMonthEnd('1')
									);
								break;
							case 'Next 3 Months':
								userHasAttribute =
									userHasAttribute ||
									compareDate.isBetween(
										thisMonthBegin,
										futureMonthEnd('3')
									);
								break;
							case 'Next 6 Months':
								userHasAttribute =
									userHasAttribute ||
									compareDate.isBetween(
										thisMonthBegin,
										futureMonthEnd('6')
									);
								break;
							default:
								break;
						}
					});
					return userHasAttribute;
				}
			});
		}
		filterProperties.set('Retail Status', {
			filter: 'retail_status',
			selectableFilters: new Set(),
			customEvaluation: (status, filters) => {
				if (!status) {
					status = 'DIGITAL';
				}
				return filters.has(displayString(status));
			}
		});

		this.state = {
			dataFormat,
			searchableFields: [],
			searchableFieldPlaceHolder: '',
			title,
			filterProperties,
			showModal: false,
			products: [],
			productsLoaded: 0,
		};
		this.toggleCreateProductModal = this.toggleCreateProductModal.bind(
			this
		);
		this.saveTotalProductsCount = this.saveTotalProductsCount.bind(this);
		this.batchGetProductsCalls = this.batchGetProductsCalls.bind(this);
		this.appendProductsToState = this.appendProductsToState.bind(this);
		this.canSeeReleaseDate = this.canSeeReleaseDate.bind(this);
		this.canViewAllPlatforms = this.canViewAllPlatforms.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate();
	}

	async componentDidUpdate() {
		if (!this.props.platformsReady || !this.props.sunsetFeaturesReady) {
			return;
		}
		this.componentDidUpdate = null;

		await this.getProductData('count=true', this.saveTotalProductsCount);

		this.batchGetProductsCalls();
		this.getTableData();
		this.canViewAllPlatforms() && this.getPlatforms();
		this.canSeeReleaseDate() && this.getReleaseDates();
		this.getStatus();
	}

	canSeeReleaseDate() {
		// NCL users are excluded if they only can view page with only NCL permissions
		const { userProfile } = this.props;
		return isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD,
			permConst.PRODUCT.VIEW.COMPANY,
		]);
	}

	canViewAllPlatforms() {
		// same as release date permissions for now
		return this.canSeeReleaseDate();
	}

	historyPush(url) {
		this.props.history.push(url);
	}

	getTableData() {
		const { userProfile } = this.props;
		let searchableFields = ['game_name', 'game_code'];
		let searchableFieldPlaceHolder = 'Search by Title or Game Code';
		if (
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.VIEW.ALL.FIRST,
				permConst.PRODUCT.VIEW.ALL.THIRD
			])
		) {
			searchableFields = ['game_name', 'game_code', 'company_name'];
			searchableFieldPlaceHolder =
				'Search by Publisher, Title or Game Code';
		}
		this.setState({
			searchableFields,
			searchableFieldPlaceHolder
		});
	}

	getTableFormat() {
		const chevronLink = ({original}) => {
			return <Link to={`/products/${original.product_id}`}><FAIcon name="chevron-right" /></Link>;
		};
		const { userProfile } = this.props;
		let tableConfiguration = [
			{ Header: 'Platform', accessor: 'platform_name', maxWidth: 200 },
			{
				Header: 'Product Name',
				accessor: 'game_name',
				Cell: ({ value, original }) =>
					(original.game_code ? `[${original.game_code}] ` : '') + value,
			},
			this.canSeeReleaseDate()
				? {
					Header: 'Est. Release Date',
					accessor: 'release_date',
					sortMethod: (a, b) => {
						return new Date(a) > new Date(b) ? 1 : -1;
					},
					Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE),
					maxWidth: 180
				  }
				: null,
			{
				Header: 'Retail Status',
				id: 'retail_status',
				accessor: (row) => row.retail_status || 'DIGITAL',
				Cell: ({ value }) => (
					<StatusText
						variant={
							['RETAIL_ACTIVE', 'DIGITAL'].includes(value) ? 'success' : 'danger'
						}
					>
						{value}
					</StatusText>
				),
				maxWidth: 150
			},
			{
				...linkColumn,
				Cell: chevronLink,
			},
		].filter((x) => x != null);
		if (
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.VIEW.ALL.FIRST,
				permConst.PRODUCT.VIEW.ALL.THIRD,
				permConst.NCL.LABEL_QUEUE.EDIT,
			])
		) {
			tableConfiguration.splice(2, 0, {
				Header: 'Publisher',
				accessor: 'company_name',
			});
		}
		return tableConfiguration;
	}

	getStatus() {
		const { filterProperties } = this.state;
		filterProperties
			.get('Retail Status')
			.selectableFilters.add('Retail Active');
		filterProperties
			.get('Retail Status')
			.selectableFilters.add('Retail Inactive');
		filterProperties
			.get('Retail Status')
			.selectableFilters.add('Digital');
	}

	getPlatforms() {
		const { filterProperties } = this.state;
		const { platforms } = this.props;
		if (!filterProperties.has('Platform') ||
			!(platforms.map((p) => p.platform_name).filter(
				(platformName, index, array) => array.indexOf(platformName) === index 
			).length >= 2)) {

			filterProperties.delete('Platform');
			return;
		}
		platforms.forEach((p) => {
			filterProperties.get('Platform').selectableFilters.add(p.platform_name);
		});
	}

	getReleaseDates() {
		const { filterProperties } = this.state;
		filterProperties
			.get('Release Date')
			.selectableFilters.add('Current Month');
		filterProperties
			.get('Release Date')
			.selectableFilters.add('Next Month');
		filterProperties
			.get('Release Date')
			.selectableFilters.add('Next 3 Months');
		filterProperties
			.get('Release Date')
			.selectableFilters.add('Next 6 Months');
	}

	toggleCreateProductModal() {
		this.setState({
			showModal: !this.state.showModal
		});
	}

	renderModal() {
		return (
			<CreateProductModal
				mode="CREATE"
				closeModal={this.toggleCreateProductModal}
				history={this.props.history}
				sunsetFeatures={this.props.sunsetFeatures}
				companyAgreements={this.props.companyAgreementsQuery}
			/>
		);
	}

	createProductButton() {
		const { userProfile, sunsetFeatures, sunsetFeaturesReady } = this.props;
		if (
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ADD.COMPANY,
				permConst.PRODUCT.ADD.FIRST,
			]) &&
			sunsetFeaturesReady &&
			isSunsettingFeatureStillValid(sunsetFeatures.content['create_product'])
		) {
			return (
				<Button onClick={() => this.toggleCreateProductModal()}>
					<FAIcon className="mr-1" name="plus" />
					Create Product
				</Button>
			);
		}
	}

	getProductData(q_string, callback) {
		return new Promise((resolve, reject) => {
			getProducts(q_string)
				.then((response) => {
					callback(response.data);
					resolve();
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'loading products'),
						'error',
						error
					);
					reject(error);
					this.setState({
						productsCallFailed: error
					});
				});
		});
	}

	saveTotalProductsCount(data) {
		this.setState({
			totalProducts: data.total
		});
	}

	appendProductsToState(data) {
		const { platforms } = this.props;
		const { productsLoaded } = this.state;
		let currentProducts = this.state.products;
		let products = data;
		const productLoadCount = productsLoaded + products.length;
		products = products.map(product => {
			const platformData = platforms.find(p => p.platform_code === product.platform_code);
			return {
				// ensure the retail_status field is in the model even if it was not present
				// in the original data from the endpoint
				retail_status: null,
				...product,
				platform_name: platformData?.platform_name
			};
		}).filter(Boolean);
		this.setState({
			products: currentProducts.concat(products),
			productsLoaded: productLoadCount
		});
	}

	batchGetProductsCalls() {
		const { totalProducts } = this.state;
		const limit = paginationLimits.productsList;
		let batches = generateOffsetBatches(totalProducts, limit);
		batches.forEach((offset) => {
			let q_string = `limit=${limit}&offset=${offset}`;
			this.getProductData(q_string, this.appendProductsToState);
		});
	}

	render() {
		const {
			dataFormat,
			searchableFields,
			searchableFieldPlaceHolder,
			title,
			totalProducts,
			filterProperties,
			products,
			productsLoaded,
			productsCallFailed,
		} = this.state;
		let isLoading = productsLoaded !== totalProducts;
		let sunsetMessage = getSunsetMessage(this.props.sunsetFeatures, 'display_product_message');
		return (
			<Page isLoading={isLoading && !productsCallFailed} fault={productsCallFailed}>
				<div className="productmanagement">
					<div>
						<NCLRestrictedPlatformsAlert />
						{sunsetMessage ? (<Alert variant="danger" className="alert-icon">
							{sunsetMessage}
						</Alert>) : null}
						<Title
							title={title}
							button={this.createProductButton()}
						/>
					</div>
					<FilterableTable
						data={products}
						dataFormat={dataFormat}
						filterProperties={filterProperties}
						searchableFields={searchableFields}
						searchableFieldPlaceHolder={
							searchableFieldPlaceHolder
						}
						defaultSorted={[
							{
								id: 'game_name',
								desc: false
							}
						]}
						retainPageState
					/>
					{this.state.showModal ? this.renderModal() : null}
				</div>
			</Page>
		);
	}
}

export default connectQueryData(
	connect(mapStateToProps)(ProductManagement),
	useCompanyAgreementsQuery,
	'companyAgreementsQuery',
	true,
);
