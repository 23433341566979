import React, { Component } from 'react';
import { Alert, Button, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import NCLRestrictedPlatformsAlert from '../../components/alerts/NCLRestrictedPlatformsAlert/NCLRestrictedPlatformsAlert';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import {
	EditCustomFieldsModal
} from '../../components/modals/EditCustomFieldModal/EditCustomFieldsModal';
import Page from '../../components/Page/Page';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import { assetConst } from '../../constants/assetConstants';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { loadingConstants } from '../../constants/loadingConstants';
import { permConst } from '../../constants/permConst';
import { platformCodes, platformFeatures } from '../../constants/platformConstants';
import { transferRightsConstants } from '../../constants/transferRightsConstants';
import { connectQueryData, useCompanyAgreementsQuery } from '../../hooks/queryHooks';
import { getCompanyProfile } from '../../services/companiesService';
import { getCustomFields } from '../../services/customFieldsService';
import {
	checkPartNumSet, getProductRetailPrice, getProductSummary,
	setProductStatus
} from '../../services/productsService';
import { validateActiveAgreementType } from '../../utils/companyUtils';
import { safeEval } from '../../utils/dataUtils';
import { isFeatureSunsetted, isSunsettingFeatureStillValid } from '../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import {
	determineActiveRightTransfer,
	transferDomains
} from '../../utils/transferRightsUtils';
import { isAuthorized, isFeatureActiveForPlatform } from '../../utils/userUtils';
import AssignDistributionRightsModal from './modals/AssignDistributionRightsModal';
import EditGameCodeModal from './modals/EditGameCodeModal';
import EditPartSkuNumModal from './modals/EditPartSkuNumModal';
import EditProductModal from './modals/EditProductModal';
import PhysicalOrderChecklistModal from './modals/PhysicalOrderChecklistModal';
import RequestGameCodeModal from './modals/RequestGameCodeModal';
import TransferOwnershipModal from './modals/TransferOwnershipModal';
import { canShowCodeInBoxAssets, canShowMarketingAssets, canShowPackagingAssets, canShowPhysicalOrderChecklist, canShowProjections } from './ProductProfile.helpers';
import AssetsTab from './Tabs/AssetsTab';
import DigitalCatalogTab from './Tabs/DigitalCatalogTab';
import GameConfigTab from './Tabs/GameConfigTab';
import LabelHistoryTab from './Tabs/LabelHistoryTab';
import OrdersTab from './Tabs/OrdersTab';
import ProductSummaryTab from './Tabs/ProductSummaryTab';
import ProjectionsTab from './Tabs/ProjectionsTab';
import SubmissionsTab from './Tabs/SubmissionsTab';

import './ProductProfile.css';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED,
		sunsetFeatures: state.referenceReducer.sunsetFeatures,
		sunsetFeaturesReady: state.referenceReducer.sunsetFeatures?.meta.status === loadingConstants.COMPLETED
	};
}

const checkPrice = (userProfile, product) => {
	if (!isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.VIEW.PRICE.COMPANY,
		permConst.PRODUCT.VIEW.PRICE.ALL
	])) {
		return false;
	}

	if (!safeEval(() => product.distribution.find((d) => d.right_type === 'RETAIL_PUB'))) {
		return false;
	}

	if (isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.PRICE.COMPANY])
		&& product.distribution.find((d) => d.right_type === 'RETAIL_PUB').ndid_company_id !== userProfile.companyId) {
		return false;
	}

	if (isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.PRICE.ALL])
		&& !safeEval(() => product.noa_part_number)) {
		return false;
	}

	return true;
};

/**
 * For Switch products, return two boolean values on whether a user can assign a specific
 * distribution rights. This function checks the current transfer status of the product and if
 * user's company has access or not.
 * @param  {} product
 */
const determineAssignableDistributionRights = (product) => {

	const activeTransfer = product?.active_rights_transfer;
	const transferFlags = product?.eligible_transfer_flags;

	// If product is 3DS or WiiU, dist rights ARE NOT assignable.
	// https://jira.noa.com/browse/LDBR-1751
	if (
		product.system_family === platformCodes['Nintendo 3DS'] ||
		product.system_family === platformCodes['Wii U'] ||
		transferFlags.has_ownership === false
	) {
		return { digital: false, retail: false };
	}

	return {
		// if there is an active retail transfer, no digital transfer
		digital: !!(activeTransfer.digital_transfer_flag !== 1 && transferFlags.has_digital),
		// if there is an active digital transfer, no retail transfer
		retail: !!(activeTransfer.retail_transfer_flag !== 1 && transferFlags.has_retail),
	};
};

export class ProductProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'summary',
			isProductSummaryLoaded: false,
			isProductRetailPriceLoaded: false,
			isCompanyProfileLoaded: false,
			isProductSubmissionsLoaded: false,
			isCustomFieldsLoaded: false,
			showModal: false,
			displayEditPartSkuNumButton: false,
			productId: '',
			product: null,
			submissions: [],
			mostRecentSubmissionId: null,
			submissionDetails: {},
			readOnly: false, // this state is only when ownership is being transferred
			summary: {
				alertMessageType: '',
				status: '',
				product_id: '',
				game_name: '',
				game_code: '',
				game_code_requested_date: '',
				platform_name: '',
				region_game_code: [],
				languages: [],
				noa_part_number: '',
				card_type: '',
				part_number_required_flag: '',
				publisher_part_number: '',
				price: '',
				rating: '',
				comment: '',
				product_distribution_type: '',
				distribution: [],
				canAssignDistRights: true,
				distRightAssignable: {
					retail: false,
					digital: false,
				},
				ebs_account_number: '',
				eligible_transfer_flags: {
					has_ownership: false,
					has_retail: false,
					has_digital: false
				},
				features: {
					online_mp: '',
					online_commerce: '',
					consumable_aoc: '',
					ugc: '',
					game_rating: ''
				}

			},
			digitalCatalogHasIssue: false
		};
		this.loadProductSummary = this.loadProductSummary.bind(this);
		this.loadCustomFields = this.loadCustomFields.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.updateGameCodeField = this.updateGameCodeField.bind(this);
		this.updatePartNum = this.updatePartNum.bind(this);
		this.updateProductStatus = this.updateProductStatus.bind(this);
		this.onTabSelected = this.onTabSelected.bind(this);

		// add helper functions as instance methods
		this.canShowProjections = canShowProjections.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps || prevProps.match.params.id !== this.props.match.params.id) {
			const { match } = this.props;
			const productId =
				match.params && match.params.id > 0 ? match.params.id : null;
			this.setState({
				productId: productId
			});
			this.loadProductSummary(productId);
			this.loadCompanyProfile();
			this.loadCustomFields(productId);
		}
	}
	checkSwitchAgreement() {
		const { userProfile, companyAgreementsQuery } = this.props;
		const valid =
			isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.ALL]) ||
			(companyAgreementsQuery && validateActiveAgreementType(
				companyAgreementsQuery,
				companyAgreementConstants.TYPE.DIGITAL,
				platformCodes['Nintendo Switch'],
				true,
			));
		this.setState({noSwitchAgreement: !valid});
	}

	hasDataLoaded() {
		const { platformsReady } = this.props;
		const {
			isProductSummaryLoaded,
			isCustomFieldsLoaded,
			isProductRetailPriceLoaded,
			isCompanyProfileLoaded,
			isCheckPartNumSetLoaded,
		} = this.state;

		return (
			isProductSummaryLoaded &&
			isCustomFieldsLoaded &&
			isProductRetailPriceLoaded &&
			(!this.canShowDigitalCatalog() || isCompanyProfileLoaded) &&
			isCheckPartNumSetLoaded &&
			platformsReady
		);
	}

	updateProductStatus() {
		const { summary } = this.state;
		let { match } = this.props;
		const newProductStatusValue =
			summary.retail_status === 'RETAIL_ACTIVE'
				? 'RETAIL_INACTIVE'
				: 'RETAIL_ACTIVE';
		const messageText =
			summary.retail_status === 'RETAIL_ACTIVE'
				? 'retail_inactive'
				: 'retail_active';
		setProductStatus(match.params.id, newProductStatusValue)
			.then(() => {
				toasterNotify(`The retail status has changed to ${messageText}`, 'success');
				const newSummary = { ...summary };
				newSummary.retail_status = newProductStatusValue;
				this.setState({
					summary: { ...newSummary },
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'setting new retail status'),
					'error',
					error,
				);
			});
	}

	generateSummary(summary) {
		if (summary) {
			if (
				!summary.company_name &&
				summary.distribution &&
				summary.distribution.length > 0
			) {
				const distRights = summary.distribution.find((distribution) => {
					return (
						distribution.right_type ===
							transferRightsConstants.DISTRIBUTION_TYPE.RETAIL ||
						distribution.right_type ===
							transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL
					);
				});

				if (distRights) {
					summary.company_name = distRights.company_name;
				}
			}
			summary.title = summary.game_name;
			if (summary.game_code) {
				summary.title += ` (${summary.game_code})`;
			}
		}
		return summary;
	}

	loadProductSummary(productId) {
		const { userProfile } = this.props;

		this.setState({
			isProductSummaryLoaded: false
		});
		getProductSummary(productId)
			.then((response) => {
				if (checkPrice(userProfile, response.data)) {
					this.loadProductRetailPrice(productId);
				} else {
					this.setState({
						isProductRetailPriceLoaded: true
					});
				}
				const summary = this.generateSummary(response.data);
				if (summary.platform_code === platformCodes['Nintendo Switch']) {
					this.checkSwitchAgreement();
				}
				this.setState(
					{
						product: response.data,
						summary,
						readOnly: determineActiveRightTransfer(
							response.data,
							transferDomains.PRODUCT_SUMMARY,
						),
						distRightAssignable: determineAssignableDistributionRights(response.data),
					},
					() => {
						if (
							isAuthorized(userProfile.permissions, [permConst.PRODUCT.EDIT.PARTSKU])
						) {
							this.displayEditPartSkuNumButton();
						} else {
							this.setState({
								isCheckPartNumSetLoaded: true,
							});
						}
					},
				);
			})
			.catch((e) => {
				if (e.response && e.response.status === 401) {
					this.setState({
						noSwitchAgreement: true,
						readOnly: true
					});
				} else {
					toasterNotify(
						createMessageForError(e, 'retrieving product data'),
						'error',
						e
					);
				}
				this.setState({
					summary: { error: e },
				});
			})
			.finally(() => {
				this.setState({
					isProductSummaryLoaded: true
				});
			});
	}

	loadCompanyProfile() {
		const { userProfile } = this.props;

		if (!this.canShowProductSummaryAndGameConfig(userProfile)) {
			return;
		}

		this.setState({
			isCompanyProfileLoaded: false
		});

		getCompanyProfile(userProfile.companyId)
			.then((response) => {
				this.setState({
					companyProfile: response.data
				});
			})
			.catch((e) => {
				toasterNotify(createMessageForError(e, 'retrieving company profile'), 'error', e);
			})
			.finally(() => {
				this.setState({
					isCompanyProfileLoaded: true
				});
			});
	}

	loadCustomFields(productId) {
		const { userProfile } = this.props;
		const canView = isAuthorized(userProfile.permissions,
			[permConst.CUSTOM_FIELD.VIEW.ALL]);
		if (!canView) {
			return this.setState({
				isCustomFieldsLoaded: true,
				customFields: null
			});
		}
		this.setState({
			isCustomFieldsLoaded: false
		});
		getCustomFields('product', String(productId))
			.then((response) => {
				this.setState({
					customFields: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving custom fields'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isCustomFieldsLoaded: true
				});
			});
	}

	loadProductRetailPrice(productId) {
		this.setState({
			isProductRetailPriceLoaded: false
		});
		getProductRetailPrice(productId)
			.then((response) => {
				this.setState({
					productRetailPrice: response.data.retail_price
				});
			})
			.catch((error) => {
				toasterNotify('There was an error retrieving price', 'warning', error, {autoClose: 4000});
			})
			.finally(() => {
				this.setState({
					isProductRetailPriceLoaded: true
				});
			});
	}

	toggleModal(modalType, callback) {
		const type = this.state.showModal ? null : modalType;

		this.setState({
			showModal: !this.state.showModal,
			modalType: type,
			modalCallback: callback
		});
	}

	updateGameCodeField(updatedProductSummary) {
		this.setState({
			summary: updatedProductSummary
		});
	}

	updatePartNum(newValue) {
		let summ = this.state.summary;
		summ.publisher_part_number = newValue['part_number'];
		this.setState({
			summary: summ
		});
	}

	generateStatusTitleProps() {
		const retailStatus = this.state.summary.retail_status;
		let retailStatusClass = '';
		let retailStatusText = '';
		if (retailStatus) {
			retailStatusClass =
				retailStatus === 'RETAIL_ACTIVE'
					? 'status-success'
					: 'status-danger';
			retailStatusText =
				retailStatus === 'RETAIL_ACTIVE'
					? 'Retail Active'
					: 'Retail Inactive';
		}
		const titleStatus = {};
		titleStatus['class'] = retailStatusClass;
		titleStatus['text'] = retailStatusText;
		return titleStatus;
	}

	displayRequestGameCodeButton() {
		if (
			!isAuthorized(this.props.userProfile.permissions, [
				permConst.PRODUCT.REQUEST.GAME_CODE
			]) || !isSunsettingFeatureStillValid(this.props?.sunsetFeatures?.content['lotcheck_features'])
		) {
			return false;
		}
		return (
			this.state.summary &&
			!this.state.summary.game_code &&
			this.state.summary.game_name !== '' &&
			!this.state.summary.game_code_requested_date &&
			this.state.summary.platform_name !== 'Nintendo Switch'
		);
	}

	displayEditPartSkuNumButton() {
		const { userProfile } = this.props;
		const platformCode = this.state.summary.platform_code;
		if (!isFeatureActiveForPlatform(userProfile, platformFeatures.PHYSICAL_ORDER, platformCode)) {
			this.setState({
				displayEditPartSkuNumButton: false,
				isCheckPartNumSetLoaded: true
			});
			return;
		}
		let display;
		checkPartNumSet(this.state.summary.product_id)
			.then((response) => {
				display = response.status === 200 && response.data !== false;
			})
			.catch(() => {
				display = false;
			})
			.finally(() => {
				this.setState({
					displayEditPartSkuNumButton: display,
					isCheckPartNumSetLoaded: true
				});
			});
	}

	displayAssignDistributionRightsButton() {
		const { userProfile } = this.props;
		const { summary } = this.state;
		return (
			isFeatureActiveForPlatform(
				userProfile,
				platformFeatures.DISTRIBUTION_TRANSFERS,
				summary.platform_code,
			) &&
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.REQUEST.COMPANY,
			]) &&
			summary?.eligible_transfer_flags?.has_ownership &&
			(summary?.eligible_transfer_flags?.has_retail ||
				summary?.eligible_transfer_flags?.has_digital)
		);
	}

	displayTransferOwnershipButton() {
		const { userProfile } = this.props;
		const { summary } = this.state;
		return (
			isAuthorized(userProfile.permissions, [permConst.PRODUCT.RIGHT_TRANSFER.REQUEST.COMPANY]) &&
			summary?.eligible_transfer_flags?.has_ownership
		);
	}

	displayRightsTransferAlert() {
		const { userProfile } = this.props;
		const { summary } = this.state;
		const { companyId } = userProfile;

		// internal user path
		if (isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD
		])) {
			return summary.active_rights_transfer.retail_transfer_flag === 1 ||
				summary.active_rights_transfer.digital_transfer_flag === 1;
		}

		// external user path
		return (
			summary?.distribution?.some(
				(transfer) => transfer.right_type === 'RETAIL_PUB' && transfer.ndid_company_id === companyId,
			) && summary.active_rights_transfer.retail_transfer_flag === 1
		) || (
			summary?.distribution?.some(
				(transfer) => transfer.right_type === 'DIGITAL_PUB' && transfer.ndid_company_id === companyId,
			) && summary.active_rights_transfer.digital_transfer_flag === 1
		);
	}

	renderTitleButton() {
		const { userProfile, sunsetFeatures } = this.props;
		const { summary, readOnly, distRightAssignable } = this.state;
		const { platform_name } = summary;

		const isPlatformSwitch = platform_name === 'Nintendo Switch';
		const canRequestGameCode = this.displayRequestGameCodeButton();
		const canEditProductDetails =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ADD.COMPANY,
				permConst.PRODUCT.ADD.FIRST
			]) && !isPlatformSwitch && isSunsettingFeatureStillValid(sunsetFeatures?.content['lotcheck_features']);
		const canEditGameCode =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.EDIT.GAME_CODE
			]) && !isPlatformSwitch && isSunsettingFeatureStillValid(sunsetFeatures?.content['lotcheck_features']);
		const canEditPublisherPartSku =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.EDIT.PARTSKU
			]) && this.state.displayEditPartSkuNumButton;
		const canEditCustomField =
			isAuthorized(userProfile.permissions, [
				permConst.CUSTOM_FIELD.VIEW.ALL
			]);

		const canAssignDistributionRights =
			this.displayAssignDistributionRightsButton();
		const assignDistributionRightsDisabled =
			!(distRightAssignable?.digital) && !(distRightAssignable?.retail);

		const canTransferOwnership =
			this.displayTransferOwnershipButton() &&
				!isPlatformSwitch &&
				!isFeatureSunsetted(sunsetFeatures?.content, 'ownership_transfers');
		const canEditProductStatus =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.EDIT.STATUS
			]) && summary.retail_status_editable;

		const showSeparator =
			(canEditProductDetails ||
				canEditGameCode ||
				canEditPublisherPartSku) &&
			(canAssignDistributionRights ||
				canTransferOwnership ||
				canEditProductStatus);

		const showTitleButton =
			canEditProductDetails ||
			canEditGameCode ||
			canEditPublisherPartSku ||
			canAssignDistributionRights ||
			canTransferOwnership ||
			canEditProductStatus ||
			canEditCustomField;

		if (!showTitleButton) {
			return null;
		}

		return (
			<>
				{canRequestGameCode && (
					<Button
						type="button"
						variant="primary"
						id="requestGameCodeModalButton"
						onClick={(e) => this.toggleModal(e.target.id)}
						disabled={readOnly}
					>
						Request Game Code
					</Button>
				)}
				{platform_name !== '' && (
					<MeatballDropdown
						id="page-context-menu"
						toggleSize='lg'
					>
						{canEditProductDetails && (
							<MeatballDropdown.Item
								eventKey="editProductDetailButton"
								id="editProductDetailButton"
								onSelect={(e) => this.toggleModal(e)}
								disabled={readOnly}
							>
								Edit Product Info
							</MeatballDropdown.Item>
						)}
						{canEditGameCode && (
							<MeatballDropdown.Item
								eventKey="editGameCodeButton"
								id="editGameCodeButton"
								onSelect={(e) => this.toggleModal(e)}
								disabled={readOnly}
							>
								Edit Game Code
							</MeatballDropdown.Item>
						)}
						{canEditCustomField && (
							<MeatballDropdown.Item
								eventKey="editCustomFieldsButton"
								id="editCustomFieldsButton"
								onSelect={(e) => this.toggleModal(e)}
							>
								Edit Custom Fields
							</MeatballDropdown.Item>
						)}
						{canEditPublisherPartSku && (
							<MeatballDropdown.Item
								eventKey="editSkuPartNum"
								id="editSkuPartNum"
								onSelect={(e) => this.toggleModal(e)}
								disabled={readOnly || determineActiveRightTransfer(
									summary,
									transferDomains.PROJECTION,
								)}
							>
								Edit Publisher Part/SKU Number
							</MeatballDropdown.Item>
						)}
						{showSeparator && <MeatballDropdown.Divider />}
						{canAssignDistributionRights && (
								<MeatballDropdown.Item
									eventKey="assignDistributionRightsButton"
									id="assignDistributionRightsButton"
									onSelect={(e) => this.toggleModal(e)}
									disabled={assignDistributionRightsDisabled}
								>
									<span className={!assignDistributionRightsDisabled ? 'text-danger' : null}>
										Assign Distribution Rights
									</span>
								</MeatballDropdown.Item>
							)}
						{canTransferOwnership && (
							<MeatballDropdown.Item
								eventKey="transferOwnershipButton"
								id="transferOwnershipButton"
								onSelect={(e) => {
									this.toggleModal(e);
								}}
								disabled={readOnly}
							>
								<span className="text-danger">
									Transfer Ownership
								</span>
							</MeatballDropdown.Item>
						)}
						{canEditProductStatus && (
							<MeatballDropdown.Item
								eventKey="6"
								onSelect={this.updateProductStatus}
								disabled={readOnly || determineActiveRightTransfer(
									summary,
									transferDomains.RETAIL_ACTIVATION
								)}
							>
								{summary.retail_status === 'RETAIL_ACTIVE' ? (
									<span className={[(!readOnly && !determineActiveRightTransfer(
											summary,
											transferDomains.RETAIL_ACTIVATION
										)) ? 'text-danger' : null].join(' ')}>
										Inactivate Retail Product
									</span>
								) : (
									<span>Activate Retail Product</span>
								)}
							</MeatballDropdown.Item>
						)}
					</MeatballDropdown>
				)}
			</>
		);
	}

	renderAlertHeader() {
		const { companyAgreementsQuery, userProfile } = this.props;
		const { summary, noSwitchAgreement } = this.state;
		const isPublisher = isAuthorized(userProfile.permissions, [permConst.COMPANY.VIEW.COMPANY]);
		const isNCL = isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.ALL.NCL]);
		const alerts = [];
		if (!companyAgreementsQuery && isPublisher) {
			const message =
				'Agreements could not be retrieved and functions that require a valid agreement have been disabled. Reload or contact site support for assistance.';
			alerts.push(<Alert variant={'danger'} key="alert_0">{message}</Alert>);
		} else if (noSwitchAgreement && isPublisher) {
			const message =
				'The Global Digital agreement has expired. Please renew the agreement in the Nintendo Developer Portal to proceed.';
			alerts.push(<Alert variant={'danger'} key="alert_1">{message}</Alert>);
		}
		if (summary.game_name !== '' && !summary.game_code) {
			const message =
				'Product has been created. A game code will be provided in 7-10 days.';
			alerts.push(<Alert variant={'success'} key="alert_2">{message}</Alert>);
		}
		if (this.displayRightsTransferAlert()) {
			const message =
				'The product is pending a rights transfer. Some actions are disabled until the transfer is completed or rescinded.\n';
			alerts.push(<Alert variant={'danger'} key="alert_3">{message}</Alert>);
		}
		if (summary.restricted_alerts) {
			summary.restricted_alerts.forEach(({ message, variant }, index) => {
				alerts.push(<Alert className="alert-icon" variant={variant} key={`restricted_alert_${index}`}>{message}</Alert>);
			});
		}
		if (isNCL) {
			alerts.push(<NCLRestrictedPlatformsAlert singlePlatform={summary.platform_code} key="alert_5" />);
		}
		if (alerts.length > 0) {
			return (
				<div>{alerts}</div>
			);
		}
		return null;
	}

	renderModal() {
		const { userProfile } = this.props;
		switch (this.state.modalType) {
			case 'requestGameCodeModalButton':
				return (
					<RequestGameCodeModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productSummary={this.state.summary}
						updateGameCodeField={this.updateGameCodeField}
					/>
				);
			case 'editProductDetailButton':
				return (
					<EditProductModal
						mode="EDIT"
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productSummary={this.state.summary}
						updateState={this.loadProductSummary}
					/>
				);
			case 'editGameCodeButton':
				return (
					<EditGameCodeModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						updateGameCodeField={this.updateGameCodeField}
						productSummary={this.state.summary}
						history={this.props.history}
						loadProductSummary={this.loadProductSummary}
					/>
				);
			case 'editSkuPartNum':
				return (
					<EditPartSkuNumModal
						show={true}
						closeModal={this.toggleModal}
						productId={this.state.summary.product_id}
						partNum={this.state.summary.publisher_part_number}
						updatePartNumber={this.updatePartNum}
					/>
				);
			case 'physicalOrderChecklist':
				return (
					<PhysicalOrderChecklistModal
						productId={this.state.summary.product_id}
						closeModal={this.toggleModal}
						show={true}
					/>
				);
			case 'assignDistributionRightsButton':
				return (
					<AssignDistributionRightsModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						history={this.props.history}
						productSummary={this.state.summary}
						distRightAssignable={this.state.distRightAssignable}
					/>
				);
			case 'transferOwnershipButton':
				return (
					<TransferOwnershipModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						history={this.props.history}
						productSummary={this.state.summary}
					/>
				);
			case 'editCustomFieldsButton':
				return (
					<EditCustomFieldsModal
						show={true}
						onClose={this.toggleModal}
						onCompletion={this.loadCustomFields}
						customFields={this.state.customFields}
						entityId={this.state.summary.product_id}
					/>
				);
			default:
				console.log('[ProductProfile] INVALID MODAL TYPE'); // eslint-disable-line no-console
		}
	}

	onTabSelected(activeKey) {
		this.setState({
			activeTab: activeKey
		});
	}

	canShowSubmissions(userProfile) {
		const { summary } = this.state;
		const isProductOwner =
			summary &&
			summary.eligible_transfer_flags &&
			summary.eligible_transfer_flags.has_ownership;
		const isExternal = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.COMPANY
		]);
		const isInternal = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD
		]);

		return isInternal || (isProductOwner && isExternal);
	}

	canShowProductSummaryAndGameConfig(userProfile) {
		// true for all users except NCL users
		return isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.ALL.CONFIDENTIAL,
			permConst.PRODUCT.VIEW.COMPANY,
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD,
		]);
	}

	// criteria

	// digital catalog is enabled for product's platform
	// AND 
	// 1. user is internal and has view.all privileges
	// -or-
	// 2a. company has digital dist rights for product AND
	// 2b. user has view.company permissions
	canShowDigitalCatalog() {
		const { distribution,platform_code,  } = this.state.summary;
		const { userProfile, companyAgreementsQuery } = this.props;

		// platform features check
		if (
			!isFeatureActiveForPlatform(userProfile, platformFeatures.DIGITAL_CODES, platform_code, 'view')) {
			return false;
		}

		// internal users permissions check
		if (isAuthorized(userProfile.permissions, [
			permConst.DIGITAL_ORDER.VIEW.ALL,
			permConst.PRODUCT.DIGITAL_CATALOG.VIEW.ALL,
		])) {
			return true;
		}

		// external users permissions and agreements check
		if (isAuthorized(userProfile.permissions, [
			permConst.DIGITAL_ORDER.VIEW.COMPANY,
			permConst.PRODUCT.DIGITAL_CATALOG.VIEW.COMPANY
		])) {
			// check if user company has active agreement and has digital rights or owner rights
			return (
				validateActiveAgreementType(
					companyAgreementsQuery,
					companyAgreementConstants.TYPE.DIGITAL,
					platform_code,
					true,
				) &&
				distribution?.some(
					(right) =>
						[
							transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP,
							transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
						].includes(right.right_type) &&
						right.ndid_company_id === userProfile.companyId,
				)
			);
		}
		return false;
	}

	canShowOrders(userProfile) {
		const { summary } = this.state;
		return (
			['RETAIL_ACTIVE', 'RETAIL_INACTIVE'].includes(summary.retail_status) &&
			isAuthorized(userProfile.permissions, [
				permConst.ORDER.DETAIL.PRODUCT.VIEW.ALL,
				permConst.ORDER.DETAIL.PRODUCT.VIEW.COMPANY,
				permConst.PHYSICAL_ORDER.VIEW.ALL,
				permConst.PHYSICAL_ORDER.VIEW.COMPANY,
			]) &&
			isFeatureActiveForPlatform(
				userProfile,
				platformFeatures.PHYSICAL_ORDER,
				summary.platform_code,
				'view'
			)
		);
	}

	canShowLabelHistory(userProfile) {
		return isAuthorized(userProfile.permissions, [
			permConst.NCL.LABEL_QUEUE.VIEW,
		]);
	}

	renderTabs() {
		const { userProfile } = this.props;
		const { product, productId, summary, activeTab, digitalCatalogHasIssue, productRetailPrice,
			customFields, readOnly } = this.state;

		const showPackagingAssets = canShowPackagingAssets(userProfile, product);
		const showMarketingAssets = canShowMarketingAssets(userProfile, product);
		const showProductSummaryAndGameConfig =
			this.canShowProductSummaryAndGameConfig(userProfile);
		const showCodeInBoxAssets = canShowCodeInBoxAssets(userProfile, summary);
		const showProjections = this.canShowProjections(userProfile, summary);
		const showSubmissions = this.canShowSubmissions(userProfile);
		const showDigitalCatalog = this.canShowDigitalCatalog();
		const showOrders = this.canShowOrders(userProfile);
		const showLabelHistory = this.canShowLabelHistory(userProfile);
		const showPhysicalOrderChecklist = canShowPhysicalOrderChecklist(userProfile, summary);
		
		return (
			<div className="Page__fill-space">
				<StatefulTabs onSelect={this.onTabSelected} transition={false} retainPageState>
					{showProductSummaryAndGameConfig && (
						<Tab eventKey="summary" title="Product Summary">
							<Page.ContentContainer>
								<ProductSummaryTab
									isSelected={activeTab === 'summary'}
									userProfile={userProfile}
									product={summary}
									productRetailPrice={productRetailPrice}
									toggleModal={this.toggleModal}
									readOnly={
										readOnly ||
										determineActiveRightTransfer(
											summary,
											transferDomains.PRODUCT_SUMMARY,
										)
									}
									sunsetFeatures={this.props.sunsetFeatures}
									customFields={customFields}
									showPhysicalOrderChecklist={showPhysicalOrderChecklist}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showProductSummaryAndGameConfig && (
						<Tab eventKey="game-config" title="Game Config">
							<Page.ContentContainer>
								<GameConfigTab
									isSelected={activeTab === 'game-config'}
									product={product}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showPackagingAssets && (
						<Tab eventKey="packaging" title="Packaging Assets">
							<Page.ContentContainer>
								<AssetsTab
									category={assetConst.CATEGORY.PACKAGING}
									isSelected={activeTab === 'packaging'}
									product={product}
									readOnly={
										readOnly ||
										determineActiveRightTransfer(
											summary,
											transferDomains.ASSET_PACKAGING,
										)
									}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showMarketingAssets && (
						<Tab eventKey="marketing" title="Marketing Assets">
							<Page.ContentContainer>
								<AssetsTab
									category={assetConst.CATEGORY.MARKETING}
									isSelected={activeTab === 'marketing'}
									product={product}
									readOnly={
										readOnly ||
										determineActiveRightTransfer(
											summary,
											transferDomains.ASSET_MARKETING,
										)
									}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showCodeInBoxAssets && (
						<Tab eventKey="cib-assets" title="Code-In-Box Assets">
							<Page.ContentContainer>
								<AssetsTab
									category={assetConst.CATEGORY.CODE_IN_BOX}
									isSelected={activeTab === 'cib-assets'}
									product={product}
									readOnly={
										readOnly ||
										determineActiveRightTransfer(
											summary,
											transferDomains.CODE_IN_BOX,
										)
									}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showProjections && (
						<Tab eventKey="projections" title="Projections">
							<Page.ContentContainer>
								<ProjectionsTab
									isSelected={activeTab === 'projections'}
									productId={productId}
									userProfile={userProfile}
									history={this.props.history}
									distribution={summary.distribution}
									readOnly={
										readOnly ||
										determineActiveRightTransfer(
											summary,
											transferDomains.PROJECTION,
										)
									}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showSubmissions && (
						<Tab eventKey="submissions" title="Submissions">
							<Page.ContentContainer>
								<SubmissionsTab
									isSelected={activeTab === 'submissions'}
									product={product}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showDigitalCatalog && (
						<Tab
							eventKey="digital-catalog"
							title={
								<>
									Digital Catalog{' '}
									{digitalCatalogHasIssue && (
										<FAIcon name="exclamation-circle" className="text-danger" />
									)}{' '}
								</>
							}
						>
							<Page.ContentContainer>
								<DigitalCatalogTab
									isSelected={activeTab === 'digital-catalog'}
									productId={productId}
									product={summary}
									flagIssue={() => {
										this.setState({ digitalCatalogHasIssue: true });
									}}
									rightsHolders={summary.distribution}
									productPlatformCode={summary.platform_code}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showOrders && (
						<Tab eventKey="orders" title="Orders">
							<Page.ContentContainer>
								<OrdersTab
									isSelected={activeTab === 'orders'}
									productId={productId}
									rightsHolders={summary.distribution}
									showPhysicalOrderChecklist={showPhysicalOrderChecklist}
									toggleModal={this.toggleModal}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
					{showLabelHistory && (
						<Tab eventKey="label-history" title="Label History">
							<Page.ContentContainer>
								<LabelHistoryTab 
									isSelected={activeTab === 'label-history'}
									productId={productId}
								/>
							</Page.ContentContainer>
						</Tab>
					)}
				</StatefulTabs>
			</div>
		);
	}

	render() {
		const { summary, isProductSummaryLoaded } = this.state;
		
		const isLoading = !this.hasDataLoaded();
		const isFault = isProductSummaryLoaded && summary.error;
		return (
			<Page documentTitle={summary.title || ''} fault={isFault}>
				{isLoading ? (
					<Loading />
				) : (
					<>
						{this.renderAlertHeader()}
						<Breadcrumb>
							<Breadcrumb.Item to="/products">Products</Breadcrumb.Item>
							<Breadcrumb.Item active>{summary.title}</Breadcrumb.Item>
						</Breadcrumb>
						<Title
							title={summary.title}
							subtitle={summary.platform_name}
							status={this.generateStatusTitleProps()}
							button={this.renderTitleButton()}
						/>
						{this.renderTabs()}

						{this.state.showModal && this.state.modalType
							? this.renderModal()
							: null}
					</>
				)}
			</Page>
		);
	}
}

export default connectQueryData(
	connect(mapStateToProps)(ProductProfile), 
	useCompanyAgreementsQuery, 
	'companyAgreementsQuery',
	true,
);
