import React, { useMemo, VFC } from 'react';
import { useQuery } from 'react-query';

import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { TFilterProperties } from '../../components/FilterBar/FilterBar';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { getDisclosedUsers } from '../../services/usersService';
import { dateFormat, formatDate } from '../../utils/dateUtils';
import { dateRangeEvaluation } from '../../utils/filterUtils';
import { isValidEmailAddresss } from '../../utils/stringUtils';
import ActiveCell from '../../components/cells/ActiveCell/ActiveCell';

const DisclosureAudit:VFC = () => {
	const auditQuery = useQuery('getDisclosedUsers', () => getDisclosedUsers());
	const auditData = auditQuery.data?.data;
	const tableFormat = useMemo(() => {
		return [
			{ Header: 'User Name', accessor: 'user_name' },
			{ Header: 'NDID', accessor: 'login_id' },
			{ Header: 'Company', accessor: 'company_name' },
			{
				Header: 'Access',
				accessor: 'disclosed_flag',
				maxWidth: 80,
				resizable: false,
				Cell: ActiveCell,
			},
			{
				Header: 'Date Added',
				accessor: 'initial_date',
				width: 80,
				Cell: ({ value }: { value: string }) => formatDate(value, dateFormat.DATE),
			},
			{
				Header: 'Email',
				accessor: 'email_address',
				width: 200,
				Cell: ({ value }: { value: string }) =>
					isValidEmailAddresss(value) ? <a href={`mailto:${value}`}>{value}</a> : value,
			},
		];
	}, []);
	const filterProperties: TFilterProperties = useMemo(() => {
		return new Map([
			[
				'Date Added',
				{
					filter: 'initial_date',
					selectableFilters: 'datetime_range',
					customEvaluation: dateRangeEvaluation,
				},
			],
		]);
	}, []);
	return (
		<Page isLoading={auditQuery.isLoading} fault={auditQuery.error}>
            <Title button={
                undefined
            }>
                Publisher Disclosure Audit
            </Title>
            <FilterableTable 
                dataFormat={tableFormat}
                data={auditData}
                filterProperties={filterProperties}
				searchableFields={['company_name', 'user_name', 'login_id', 'email_address']}
				searchableFieldPlaceHolder="Search for User Name, Company, NDID or Email..."
				retainPageState
				enableCSVDownload
            />
        </Page>
	);
};

export default DisclosureAudit;
