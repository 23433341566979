import { assetConst } from '../constants/assetConstants';
import { displayString } from './stringUtils';
import { postGeneratePdbAccessToken } from '../services/eventsService';

const keepList = ['DRI', 'TV', 'NCL'];

export function formatAssetStatus(status: string): string {
	if (status in assetConst.STATUS) {
		return displayString((assetConst.STATUS as Record<string, any>)[status], keepList);
	}
	return status;
}

export function formatAssetType(type: typeof assetConst.TYPE[keyof typeof assetConst.TYPE]) {
	if (type === assetConst.TYPE.DISPLAY_SIGNAGE) {
		return 'Display/Signage';
	}
	if (type === assetConst.TYPE.RELEASE_ALERT) {
		return 'Release/Media Alert';
	}
	if (type === assetConst.TYPE.SWITCH_DLC_INSERT) {
		return 'Switch DLC Insert';
	}
	if (type === assetConst.TYPE.SWITCH2_DLC_INSERT) {
		return 'Switch 2 DLC Insert';
	}
	if (type && assetConst.TYPE[type]) {
		return displayString(assetConst.TYPE[type], keepList);
	}
	return type;
}

export function formatAssetCategory(category: keyof typeof assetConst.CATEGORY) {
	if (category && assetConst.CATEGORY[category]) {
		return displayString(assetConst.CATEGORY[category], keepList);
	}
	return category;
}

export function classForAssetStatus(status: string, classPrefix='status') {
	switch (status) {
		case assetConst.STATUS.APPROVED:
		case assetConst.STATUS.APPROVED_WITH_CHANGES:
			return `${classPrefix}-success`;
		case assetConst.STATUS.FINAL_LABEL_UPLOADED:
		case assetConst.STATUS.PENDING_PROOF_ACCEPT:
		case assetConst.STATUS.PENDING_REVIEW:
		case assetConst.STATUS.PRINT_PROOF_PENDING:
		case assetConst.STATUS.REVIEWED:
		case assetConst.STATUS.REVIEWED_WITH_CHANGES:
		case assetConst.STATUS.SUBMITTED_TO_NCL:
			return `${classPrefix}-info`;
		case assetConst.FINAL_LABEL_FORGO_STATUS.ACCEPT_FORGO:
		case assetConst.STATUS.FINAL_LABEL_UPLOADING:
		case assetConst.STATUS.PRINT_PROOF_UPLOADING:
		case assetConst.STATUS.FILES_UPLOADING:
		case assetConst.FINAL_LABEL_FORGO_STATUS.REQUEST_FORGO:
			return `${classPrefix}-warning`;
		case assetConst.STATUS.FILE_UPLOAD_FAILED:
		case assetConst.STATUS.FINAL_LABEL_REJECTED:
		case assetConst.STATUS.FINAL_LABEL_UPLOAD_FAILED:
		case assetConst.STATUS.NCL_PROOF_UPLOAD_FAILED:
		case assetConst.STATUS.NCL_ACCEPTED_UPLOAD_FAILED:
		case assetConst.STATUS.PRINT_PROOF_UPLOAD_FAILED:
		case assetConst.STATUS.PRINT_PROOF_REJECTED:
		case assetConst.STATUS.REJECTED:
		case assetConst.STATUS.REQUIRED:
			return `${classPrefix}-danger`;
		default:
			return `${classPrefix}-info`;
	}
}

/**
 * Retrieve a DA token for a marketing event asset for use with pdb-core endpoints.
 * @param  {} asset Combined Asset data model from marketing events service and pdb core service data
 * @param  {} operation='view' Operation to be performed by the call. Can be 'view', 'review', 'upload' or 'comment'
 */
export async function getDATokenForMarketingEventAsset(
	asset: { id: number; asset_category: keyof typeof assetConst.CATEGORY },
	operation: 'view' | 'comment' | 'review' | 'upload' = 'view',
) {
	if (!['view', 'review', 'upload', 'comment'].includes(operation)) {
		throw new Error(`Invalid valid operation given ("${operation}")`);
	}
	if (asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT && asset.id) {
		return (await postGeneratePdbAccessToken(String(asset.id), operation)).headers[
			'x-pdb-authorization'
		];
	}
	return null;
}

/**
 * Tests if an extension (or list of extensions) matches a given filename or path
 * @param  {} fileNameOrPath name/path of file to test
 * @param  {} extensions array or single string
 */
export function doesFileHaveExtension(fileNameOrPath: string, extensions: string | string[]) {
	extensions = Array.isArray(extensions) ? extensions : [extensions];
	return extensions.some(ext => new RegExp(`\\.${ext}$`, 'i').test(fileNameOrPath));
}
/**
 * Tests if a list of files has a matching extension. Returns false if
 * no file list is given. returns true if no extension(s) is given
 * @param  {} fileList array of file names or paths
 * @param  {} extensions string or array of acceptable extensions
 */
export function doAllFilesHaveExtension(fileList: AsperaFile[], extensions: string | string[]) {
	return !!fileList && (
		!extensions ||
			fileList.every((file) =>
				doesFileHaveExtension(file.file_name, extensions))
	);
}

/**
 * Tests if a file name include the game code in its name
 * @param  {} fileName array of file names or paths
 * @param  {} gameCode string or array of acceptable extensions
 */
export function fileNameHasGameCode(fileName?: string, gameCode?: string) {
	return !!fileName && !!gameCode && fileName
		.split('.')[0]
		.toUpperCase()
		.indexOf(gameCode?.toUpperCase()) > -1;
}
