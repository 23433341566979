import React, { VFC } from 'react';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { cleanGameConfigurationValue, gameConfigFields } from './GameConfiguration.helpers';

interface SwitchGameConfigurationProps {
	gameConfiguration: any;
}
const SwitchGameConfiguration: VFC<SwitchGameConfigurationProps> = ({ gameConfiguration }) => {
	if (gameConfiguration) {
		const containsFrench = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_FRENCH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_FRENCH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICAN_FRENCH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICAN_FRENCH]
							.FieldValue,
					) === 'Yes'),
		);

		const containsSpanish = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_SPANISH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_SPANISH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMIERICA_SPANISH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMIERICA_SPANISH]
							.FieldValue,
					) === 'Yes'),
		);

		const containsEnglish = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_UK_ENGLISH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_UK_ENGLISH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICA_ENGLISH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICA_ENGLISH]
							.FieldValue,
					) === 'Yes'),
		);
		return (
			<>
				<SectionTitle>Program Specifications</SectionTitle>
				<PropertyDisplay label="Contains French">{containsFrench}</PropertyDisplay>
				<PropertyDisplay label="Contains Spanish">{containsSpanish}</PropertyDisplay>
				<PropertyDisplay label="Contains English">{containsEnglish}</PropertyDisplay>
				<PropertyDisplay label="Amiibo Compatible">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.AMIIBO_COMPATIBLE] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<PropertyDisplay label="Save data Cloud back up settings">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.SAVE_DATA_CLOUD_BACK_UP_SETTINGS
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Input Devices</SectionTitle>
				<PropertyDisplay label="Nintendo Switch Pro Controller">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.NINTENDO_SWITCH_PRO_CONTROLLER
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Play Modes</SectionTitle>
				<PropertyDisplay label="TV Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.TV_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>

				<PropertyDisplay label="Tabletop Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.TABLETOP_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>

				<PropertyDisplay label="Handlheld Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.HANDHELD_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>NEX</SectionTitle>
				<PropertyDisplay label="Matchmaking">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.NEX_MATCHMAKING] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>E-commerce</SectionTitle>
				<PropertyDisplay label="In-game purchases">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.IN_GAME_PURCHASES] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Independent Server</SectionTitle>
				<PropertyDisplay label="Matchmaking">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.INDEPENDENT_SERVER_MATCHMAKING
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
			</>
		);
	}
	return null;
};

export default SwitchGameConfiguration;
