import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouterGate } from './RouterGate';

import AnnouncementManagement from '../../containers/AnnouncementManagement/AnnouncementManagement';
import ApprovalQueue from '../../containers/ApprovalQueue/ApprovalQueue';
import AssetDetails from '../../containers/AssetDetails/AssetDetails';
import CompanyProfile from '../../containers/CompanyProfile/CompanyProfile';
import ComponentDetail from '../../containers/ComponentDetail/ComponentDetail';
import ComponentsWithoutProducts from '../../containers/ComponentsWithoutProducts/ComponentsWithoutProducts';
import ConsigneeManagement from '../../containers/ConsigneeManagement/ConsigneeManagement';
import CustomFieldsManagement from '../../containers/CustomFieldManagement/CustomFieldsManagement';
import Demo from '../../containers/Demo/Demo';
import DigitalCodesDetail from '../../containers/DigitalCodesDetail/DigitalCodesDetail';
import DigitalCodesManagement from '../../containers/DigitalCodesManagement/DigitalCodesManagement';
import DigitalCodesOrderCreate from '../../containers/DigitalCodesOrderCreate/DigitalCodesOrderCreate';
import DisclosureAudit from '../../containers/DisclousureAudit/DisclosureAudit';
import EventDetail from '../../containers/EventDetail/EventDetail';
import EventManagement from '../../containers/EventManagement/EventManagement';
import EventProductDetail from '../../containers/EventProductDetail/EventProductDetail';
import EventReports from '../../containers/EventReports/EventReports';
import FamilySetup from '../../containers/FamilySetup/FamilySetup';
import FeatureManagement from '../../containers/FeatureManagement/FeatureManagement';
import Features from '../../containers/Features/Features';
import FreightForwarderAssociation
	from '../../containers/FreightForwarderAssociation/FreightForwarderAssociation';
import ManageFreightForwarders from '../../containers/FreightForwarderManagement/ManageFreightForwarders';
import HelpTopic from '../../containers/HelpTopics/HelpTopics';
import Home from '../../containers/Home/Home';
import Logout from '../../containers/Login/Logout';
import LotcheckQueue from '../../containers/LotcheckQueue/LotcheckQueue';
import MaintenancePage from '../../containers/Maintenance/MaintenancePage';
import ManagePackoutFacilities from '../../containers/ManagePackoutFacilities/ManagePackoutFacilities';
import NCLPortal from '../../containers/NCLPortal/NCLPortal';
import NotFound from '../../containers/NotFound/NotFound';
import NotSignedIn from '../../containers/NotSignedIn/NotSignedIn';
import OrderCreate from '../../containers/OrderCreate/OrderCreate';
import OrderList from '../../containers/Orders/OrderList';
import OrderReview from '../../containers/Orders/OrderReview';
import PhysicalOrdersCreate from '../../containers/PhysicalOrdersCreate/PhysicalOrdersCreate';
import PhysicalOrdersDetail from '../../containers/PhysicalOrdersDetail/PhysicalOrdersDetail';
import PhysicalOrdersManagement from '../../containers/PhysicalOrdersManagement/PhysicalOrdersManagement';
import PrivacyPolicy from '../../containers/PrivacyPolicy/PrivacyPolicy';
import ProductManagement from '../../containers/ProductManagement/ProductManagement';
import ProductProfile from '../../containers/ProductProfile/ProductProfile';
import DistributionRightsRequestDetails from '../../containers/ProductRights/Distribution/ProductDistributionTransfer';
import DistributionRightsRequestsTable from '../../containers/ProductRights/Distribution/ProductDistributionTransferTable';
import ProductOwnershipTransfer from '../../containers/ProductRights/Ownership/ProductOwnershipTransfer';
import ProductOwnershipTransferTable from '../../containers/ProductRights/Ownership/ProductOwnershipTransferTable';
import OrderProjections from '../../containers/Projections/OrderProjections';
import ProjectionsManagement from '../../containers/ProjectionsManagement/ProjectionsManagement';
import PublisherManagement from '../../containers/PublisherManagement/PublisherManagement';
import ROMUploadManagement from '../../containers/ROMUploadManagement/ROMUploadManagement';
import SubmissionDetails from '../../containers/SubmissionDetails/SubmissionDetails';
import UserManagement from '../../containers/UserManagement/UserManagement';

import { useIsFeatureSunsetted, useUserProfile } from '../../hooks/reduxHooks';
import AuthExpiredDisplay from '../AuthExpiredDisplay/AuthExpiredDisplay';
import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';

export function MaintenanceRoute() {
	return (
		<Switch>
			<Route exact path="*" component={MaintenancePage} />
		</Switch>
	);
}

export function LoggedOutRouter() {
	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/logout" component={Logout} />
			<Route exact path="/privacy-policy" component={PrivacyPolicy} />
			<Route path="*" component={NotSignedIn} />
			<Redirect from="*" to="/login" />
		</Switch>
	);
}

export function MainRouter() {
	const userProfile = useUserProfile();
	const lotcheckSunset = useIsFeatureSunsetted('lotcheck_features');
	return (
		<>
			<Switch>
				<Route
					path="/logout"
					render={() => {
						return <Logout />;
					}}
				/>
				<Route exact path={['/', '/version']} component={Home} />

				{/* Routes sorted by path */}
				<Route
					exact
					path="/admin/announcements"
					render={(props) => <RouterGate props={props} Component={AnnouncementManagement} />}
				/>
				<Route
					exact
					path="/admin/appovals"
					render={(props) => <RouterGate props={props} Component={ApprovalQueue} />}
				/>
				<Route
					exact
					path="/admin/users"
					render={(props) => <RouterGate props={props} Component={UserManagement} />}
				/>
				<Route
					exact
					path="/admin/disclosure-audit"
					render={(props) => <RouterGate props={props} Component={DisclosureAudit} />}
				/>
				<Route
					exact
					path="/admin/family-setup"
					render={(props) => <RouterGate props={props} Component={FamilySetup} />}
				/>
				<Route
					exact
					path="/admin/feature-management"
					render={(props) => <RouterGate props={props} Component={FeatureManagement} />}
				/>
				<Route
					exact
					path="/admin/help-topic-management"
					render={(props) => <RouterGate props={props} Component={HelpTopic} />}
				/>
				<Route
					exact
					path="/admin/components-without-products"
					render={(props) => <RouterGate props={props} Component={ComponentsWithoutProducts} />}
				/>
				<Route
					exact
					path="/admin/product-distribution-transfer-requests"
					render={(props) => <RouterGate props={props} Component={DistributionRightsRequestsTable} />}
				/>
				<Route
					exact
					path="/admin/product-distribution-transfer-requests/:id"
					render={(props) => <RouterGate props={props} Component={DistributionRightsRequestDetails} />}
				/>
				<Route
					exact
					path="/admin/product-ownership-transfer-requests"
					render={(props) => <RouterGate props={props} Component={ProductOwnershipTransferTable} />}
				/>
				<Route
					exact
					path="/admin/product-ownership-transfer-requests/:id"
					render={(props) => <RouterGate props={props} Component={ProductOwnershipTransfer} />}
				/>
				<Route
					exact
					path="/admin/ncl-users"
					render={(props) => <RouterGate props={props} Component={UserManagement.NCL} />}
				/>
				<Route
					exact
					path="/admin/publishers"
					render={(props) => <RouterGate props={props} Component={PublisherManagement} />}
				/>
				<Route
					exact
					path="/admin/publishers/:companyId"
					render={(props) => <RouterGate props={props} Component={CompanyProfile} />}
				/>
				<Route
					exact
					path="/admin/ncl-rom-versions"
					render={(props) => <RouterGate props={props} Component={ROMUploadManagement} />}
				/>
				<Route
					exact
					path="/admin/custom-fields-management"
					render={(props) => <RouterGate props={props} Component={CustomFieldsManagement} />}
				/>
				<Route
					exact
					path="/demo"
					render={(props) => <RouterGate props={props} Component={Demo} />}
				/>
				<Route
					exact
					path="/features"
					render={(props) => <RouterGate props={props} Component={Features} />}
				/>
				<Route
					exact
					path="/marketing-events"
					render={(props) => <RouterGate props={props} Component={EventManagement} />}
				/>
				<Route
					exact
					path="/marketing-events/reports"
					render={(props) => <RouterGate props={props} Component={EventReports} />}
				/>
				<Route
					exact
					path="/marketing-events/:eventId"
					render={(props) => <RouterGate props={props} Component={EventDetail} />}
				/>
				<Route
					exact
					path="/marketing-events/products/:productId"
					render={(props) => <RouterGate props={props} Component={EventProductDetail} />}
				/>
				<Route
					exact
					path="/marketing-events/products/:eventProductId/assets/:eventAssetId"
					render={(props) => <RouterGate props={props} Component={AssetDetails} />}
				/>
				<Route
					exact
					path="/ncl"
					render={(props) => <RouterGate props={props} Component={NCLPortal} />}
				/>
				{!isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.VIEW]) ? (
					<Route
						exact
						path="/orders"
						render={(props) => <RouterGate props={props} Component={OrderList} />}
					/>
				) : (
					<Redirect exact path="/orders" to="/orders/physical" />
				)}
				{!isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.EDIT]) ? (
					<Route
						exact
						path="/orders/create"
						render={(props) => <RouterGate props={props} Component={OrderCreate} />}
					/>
				) : (
					<Redirect exact path="/orders/create" to="/orders/physical/create" />
				)}
				<Route
					exact
					path="/orders/consignees"
					render={(props) => <RouterGate props={props} Component={ConsigneeManagement} />}
				/>
				<Route
					exact
					path="/orders/digital-codes"
					render={(props) => <RouterGate props={props} Component={DigitalCodesManagement} />}
				/>
				<Route
					exact
					path="/orders/digital-codes/create"
					render={(props) => <RouterGate props={props} Component={DigitalCodesOrderCreate} />}
				/>
				<Route
					exact
					path="/orders/digital-codes/:orderHeaderId/create"
					render={(props) => <RouterGate props={props} Component={DigitalCodesOrderCreate} />}
				/>
				<Route
					exact
					path="/orders/digital-codes/:orderId"
					render={(props) => <RouterGate props={props} Component={DigitalCodesDetail} />}
				/>
				<Route
					exact
					path="/orders/manage-freight-forwarders"
					render={(props) => <RouterGate props={props} Component={ManageFreightForwarders} />}
				/>
				<Route
					exact
					path="/orders/associate-freight-forwarders"
					render={(props) => <RouterGate props={props} Component={FreightForwarderAssociation} />}
				/>
				<Route
					exact
					path="/orders/manage-packout-facilities"
					render={(props) => <RouterGate props={props} Component={ManagePackoutFacilities} />}
				/>
				<Route
					exact
					path="/orders/physical"
					render={(props) => <RouterGate props={props} Component={PhysicalOrdersManagement} />}
				/>
				<Route
					exact
					path="/orders/physical/create"
					render={(props) => <RouterGate props={props} Component={PhysicalOrdersCreate} />}
				/>
				<Route
					exact
					path="/orders/physical/:orderHeaderId/create"
					render={(props) => <RouterGate props={props} Component={PhysicalOrdersCreate} />}
				/>
				<Route
					exact
					path="/orders/physical/:orderHeaderId"
					render={(props) => <RouterGate props={props} Component={PhysicalOrdersDetail} />}
				/>
				<Route
					exact
					path="/orders/review/:orderHeaderId"
					render={(props) => <RouterGate props={props} Component={OrderReview} />}
				/>
				<Route
					exact
					path="/orders/projections"
					render={(props) => <RouterGate props={props} Component={OrderProjections} />}
				/>
				<Route
					exact
					path="/orders/projections/management"
					render={(props) => <RouterGate props={props} Component={ProjectionsManagement} />}
				/>
				<Route
					exact
					path="/orders/projections/:id"
					render={(props) => <RouterGate props={props} Component={OrderProjections} />}
				/>
				<Route
					exact
					path="/orders/:orderHeaderId/create"
					render={(props) => <RouterGate props={props} Component={OrderCreate} />}
				/>
				<Route
					exact
					path="/products"
					render={(props) => <RouterGate props={props} Component={ProductManagement} />}
				/>
				<Redirect exact path="/products/assets" to="/admin/appovals" />
				<Route
					exact
					path="/products/:id"
					render={(props) => <RouterGate props={props} Component={ProductProfile} />}
				/>
				<Route
					exact
					path="/products/:productId/assets/:assetId"
					render={(props) => <RouterGate props={props} Component={AssetDetails} />}
				/>
				<Route
					exact
					path="/products/components/:nsUid"
					render={(props) => <RouterGate props={props} Component={ComponentDetail} />}
				/>
				{!lotcheckSunset && (
					<Route
						exact
						path="/submissions"
						render={(props) => <RouterGate props={props} Component={LotcheckQueue} />}
					/>
				)}
				<Route
					exact
					path="/submission-details/:productId/:submissionId"
					render={(props) => <RouterGate props={props} Component={SubmissionDetails} />}
				/>
				<Route exact path="/privacy-policy" component={PrivacyPolicy} />
				<Route exact path="*" component={NotFound} />
			</Switch>
			<AuthExpiredDisplay />
		</>
	);
}
