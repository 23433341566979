import intersection from 'lodash/intersection';
import searchOperators from 'search-operators';
import urlon from 'urlon';

import { TActiveFilters, TFilterProperties } from './FilterBar';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { displayString } from '../../utils/stringUtils';

export const serializeFilter = (activeFilters?: TActiveFilters): string | undefined => {
	if (!activeFilters) {
		return '';
	}

	const object = convertMapToHash(activeFilters);
	const string = urlon.stringify(object);
	return string === '$' ? '' : string;
};

export const convertMapToHash = (filters: Map<any, any>) => {
	return Array.from(filters.keys()).reduce((reduction, filterName) => {
		reduction[filterName] = Array.from(filters.get(filterName)).map((item) =>
			item instanceof Date ? formatDate(item, dateFormat.DATE_YMD) : item,
		);
		return reduction;
	}, {});
};

const applyDropdownFilter = ({
	filterProperties,
	activeFilters,
	record,
}: {
	filterProperties?: TFilterProperties;
	activeFilters?: TActiveFilters | null;
	record: Record<string, any>;
}) => {
	if (!filterProperties || !activeFilters) {
		return true;
	}
	for (let [filterName, activeFilter] of activeFilters) {
		if (!filterName) {
			continue;
		}
		const columnName: string | undefined = filterProperties.get(filterName)?.filter;

		// ignore filters that don't appear in the selectable list
		const usableActiveFilter = 
			getUsableActiveFilter(filterProperties.get(filterName)?.selectableFilters, activeFilter);
		
		if (
			usableActiveFilter &&
			columnName &&
			columnName in record &&
			String(record[columnName]).split(',').length
		) {
			let recordHasAttribute: boolean | undefined;
			if (
				filterProperties.get(filterName) &&
				filterProperties.get(filterName)?.customEvaluation
			) {
				const filterDefinition = filterProperties.get(filterName);
				recordHasAttribute =
					filterDefinition?.customEvaluation &&
					filterDefinition.customEvaluation(record[columnName], usableActiveFilter);
			} else {
				const recordAttributes = String(record[columnName])
					.split(',')
					.map((item) => {
						return displayString(item.trim());
					});
				recordHasAttribute = !!intersection(
					Array.from(usableActiveFilter),
					Array.from(recordAttributes),
				).length;
			}
			if (usableActiveFilter?.size && !recordHasAttribute) {
				return false;
			}
		} else {
			return false;
		}
	}

	return true;
};

const applySearchBarFilter = ({
	searchableFields,
	record,
	searchCriteria,
}: {
	searchableFields: string[] | undefined;
	record: Record<string, any>;
	searchCriteria: searchOperators.ParseResult | null;
}) => {
	if (!searchCriteria || !searchableFields) {
		return true;
	}
	const searchContents = searchableFields.map((field) => String(record[field]).toUpperCase());

	if (searchCriteria.filters?.length) {
		for (const filter of searchCriteria.filters) {
			if (!filter) {
				continue;
			}
			const filterValue = filter.value.toUpperCase();
			if (filter.type === 'exact') {
				if (!searchContents.some((item) => item.includes(filterValue))) {
					return false;
				}
			}
			if (filter.type === 'exclude') {
				if (searchContents.some((item) => item.toUpperCase().includes(filterValue))) {
					return false;
				}
			}
			if (filter.type === 'match') {
				if (!String(record[filter.key]).toUpperCase().includes(filterValue)) {
					return false;
				}
			}
		}
	}

	return (
		!searchCriteria.terms?.length ||
		searchCriteria.terms.every((term: string) => {
			const change = searchContents.some((item) => item.includes(term.toUpperCase()));
			return change;
		})
	);
};

export const filterData = (
	data?: Record<string, any>[],
	filterProperties?: TFilterProperties,
	activeFilters?: TActiveFilters,
	searchString?: string,
	searchableFields?: string[],
) => {
	let filteredData: Record<string, any>[] = [];

	if (!data || !data.forEach) {
		return data;
	}
	let searchCriteria: searchOperators.ParseResult | null;
	try {
		if (searchString && searchableFields) {
			searchCriteria = searchString
				? searchOperators.parse(searchString, { keys: [...searchableFields] })
				: null;
		} else {
			searchCriteria = null;
		}
	} catch {
		searchCriteria = null;
	}

	data.forEach((record: Record<string, any>) => {
		const keepRecord =
			applyDropdownFilter({
				filterProperties,
				activeFilters,
				record,
			}) &&
			applySearchBarFilter({
				searchableFields,
				record,
				searchCriteria,
			});
		if (keepRecord) {
			filteredData.push(record);
		}
	});
	return filteredData;
};

export const convertHashToMap = (
	hash: Record<string, any>,
	filterProperties?: TFilterProperties,
): Map<string, any> => {
	const filters = new Map();
	if (hash) {
		Object.keys(hash).forEach((name) => {
			const filter = filterProperties?.get(name);
			if (filter) {
				if (filter.selectableFilters === 'datetime_range') {
					const dates = hash[name]
						.filter((item: any) => !isNaN(Number(new Date(item))))
						.map((item: string) => parseDateString(item)?.toDate());
					filters.set(name, new Set(dates));
				} else if (Array.isArray(hash[name]) && hash[name].length) {
					filters.set(
						name,
						new Set(
							hash[name].filter(
								(item: any) =>
									filter.selectableFilters instanceof Set &&
									filter.selectableFilters.has(item),
							),
						),
					);
				}
			}
		});
	}
	return filters;
};

export const areSameFilters = (map0: TActiveFilters, map1: TActiveFilters): boolean => {
	if (map1.size !== map0.size) {
		return false;
	}
	for (let key of map0.keys()) {
		if (
			!map1.has(key) ||
			Array.from(map0.get(key) || [])
				.sort()
				.toString() !==
				Array.from(map1.get(key) || [])
					.sort()
					.toString()
		) {
			return false;
		}
	}
	return true;
};

const getUsableActiveFilter = (
	selectableFilters?: Set<string> | 'datetime_range',
	activeFilter?: Set<string>,
) => {
	return !activeFilter || !selectableFilters || selectableFilters === 'datetime_range'
		? activeFilter
		: new Set(Array.from(activeFilter).filter((name) => selectableFilters.has(name)));
};

