import intersection from 'lodash/intersection';

import { PermissionNode } from '../constants/permConst';
import { routePermissions } from '../constants/routePermissions';
import { PlatformFeatures } from '../types/constants';
import { expandHashes } from './dataUtils';
import { isFeatureFlagEnabled } from './featureFlagUtils';


/**
 * Evaluates if a user has a required permission within a list of permissions
 *
 * @param userPermissions array of permissions in posession
 * @param requiredPermissions array of permissions to be satisfied
 * If a hash of permissions is found within the input array, values inside that
 * hash will be added to the array.
 *
 * @returns Boolean, true if any required permission is found in user permissions
 */
export const isAuthorized = (userPermissions: string[], requiredPermissions: PermissionNode[]) => {
	requiredPermissions = expandHashes(requiredPermissions) as string[];
	return intersection(userPermissions, requiredPermissions).length > 0;
};

export const isAuthorizedForUrl = (userProfile: UserProfile, path: string) => {
	const routePermission = routePermissions.get(path);
	if (!routePermission) {
		return true;
	}
	if (routePermission.platformFeatures) {
		const ok = routePermission.platformFeatures.every(
			(featureRequirement) => {
				return (
					!!userProfile &&
					featureRequirement.name in userProfile.platform_features &&
					isFeatureActiveOnAnyPlatform(
						userProfile,
						featureRequirement.name,
						featureRequirement.privilege,
					)
				);},
		);
		if (!ok) {
			return false;
		} 
	}
	if (routePermission.featureFlag && !isFeatureFlagEnabled(routePermission.featureFlag)) {
		return false;
	}
	if (routePermission.disable && routePermission.disable(userProfile)) { return false; }
	return isAuthorized(userProfile.permissions, routePermission.permissions);
};

/**
 * Determines if a user is an internal user
 * @param  {} userProfile
 */
export const userHasInternalRole = (userProfile: UserProfile) =>
	userProfile.roles.some(role => role.role_type_code === 'INTERNAL');

/**
 * Tests to see if user is a member of a group, defined by an array of
 * NDID user IDs.
 * @param  {} userProfile
 * @param  {Array} idList
 */
export const isAffiliated = (userProfile: UserProfile, idList: unknown[]) => {
	return idList.includes(userProfile.userId);
};

export const isFeatureActiveForPlatform = (
	userProfile: UserProfile,
	feature: PlatformFeatures,
	platform: PlatformCode,
	privilege: 'edit' | 'view' = 'edit',
) => {
	const capabilities = userProfile.platform_features?.[feature]?.[platform];
	return capabilities?.includes(privilege) === true;
};

export const isFeatureActiveOnAnyPlatform = (
	userProfile: UserProfile,
	feature: PlatformFeatures,
	privilege: 'edit' | 'view' = 'edit',
) => {
	return getAvailableFeaturePlatforms(userProfile, feature, privilege).length > 0;
};

export const getAvailableFeaturePlatforms = (
	userProfile: UserProfile,
	feature: PlatformFeatures,
	privilege: 'edit' | 'view',
) => {
	const specificFeature = userProfile.platform_features?.[feature];
	if (specificFeature) {
		return (Object.keys(specificFeature) as PlatformCode[]).filter((platformCode) =>
			(platformCode in specificFeature && specificFeature[platformCode].includes(privilege)) && platformCode);
	}
	return [];
};
