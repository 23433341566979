import { VFC } from 'react';
import { CellInfo } from 'react-table-v6';

import { useReferenceData } from '../../../hooks/reduxHooks';

const PlatformCell: VFC<CellInfo> = ({ value }): any => {
	const platforms = useReferenceData('platforms') as PlatformData[];
	return (
		platforms?.find((p) => p.platform_code === value)?.platform_name ?? null
	);
};

export default PlatformCell;
