import React, { VFC } from 'react';
import { Alert } from 'react-bootstrap';

import { permConst } from '../../../constants/permConst';
import { useReferenceData, useUserProfile } from '../../../hooks/reduxHooks';
import { isAuthorized } from '../../../utils/userUtils';

const NCLRestrictedPlatformsAlert: VFC<{ singlePlatform?: PlatformCode }> = ({
	singlePlatform,
}) => {
	const userProfile = useUserProfile();
	const canShow = isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.ALL.NCL]);
	const platforms: PlatformData[] = useReferenceData('platforms');
	if (
		!canShow ||
		!platforms.some(
			(platform) =>
				platform.restricted &&
				(singlePlatform ? platform.platform_code === singlePlatform : true),
		)
	) {
		return null;
	}
	return (
		<Alert variant="warning">
			Please use caution when contacting NOA personnel outside of the Nintendo Publisher Tool
			regarding labels for{' '}
			{platforms
				.filter(
					(platform) =>
						!!platform.restricted &&
						(singlePlatform ? platform.platform_code === singlePlatform : true),
				)
				.map((platform, index, array) =>
					array.length - 1 - index > 1 ? (
						<>
							<b>{platform.platform_name}</b>,{' '}
						</>
					) : array.length - 1 - index === 1 ? (
						<>
							<b>{platform.platform_name}</b> and{' '}
						</>
					) : (
						<b>{platform.platform_name}</b>
					),
				)}{' '}
			products. Make sure your contact is disclosed prior to sending emails. Only disclosed
			NOA PDR personnel can see restricted platform products in Nintendo Publisher Tool.
		</Alert>
	);
};
export default NCLRestrictedPlatformsAlert;
