import React from 'react';
import { Tab } from 'react-bootstrap';

import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import LabelQueueTab from './views/LabelQueueTab';
import NCLRestrictedPlatformsAlert from '../../components/alerts/NCLRestrictedPlatformsAlert/NCLRestrictedPlatformsAlert';

const LABEL_QUEUE_TAB = 'label-queue';

const NCLPortal = () => {
	return (
		<Page>
			<NCLRestrictedPlatformsAlert />
			<Title>NCL Portal</Title>
			<Page.FillSpace>
				<StatefulTabs transition={false} retainPageState>
					<Tab
						eventKey={LABEL_QUEUE_TAB}
						title="Label Approval Queue"
						className="Page__fill-space"
					>
						<LabelQueueTab
                            eventKey={LABEL_QUEUE_TAB}
                        />
					</Tab>
				</StatefulTabs>
			</Page.FillSpace>
		</Page>
	);
};
export default NCLPortal;
