import React, { VFC } from 'react';
import { Button } from 'react-bootstrap';
import { Column } from 'react-table';
import FAIcon from '../FAIcon/FAIcon';
import { convertToCSV } from './CSVDownload.helpers';

interface CSVDownloadProps {
	data: Record<string, any>[];
	dataFormat: Array<Column<any>>;
	disabled?: boolean;
}
const CSVDownload: VFC<CSVDownloadProps> = ({ data, dataFormat, disabled }) => {
	return (
		<Button
			size="sm"
			variant="outline-secondary"
			onClick={() => convertToCSV(data, dataFormat)}
			disabled={disabled}
			className="text-nowrap"
		>
			<FAIcon name="download" /> Export to CSV
		</Button>
	);
};

export default CSVDownload;
