import { assetConst } from '../../constants/assetConstants';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { permConst } from '../../constants/permConst';
import { platformFeatures } from '../../constants/platformConstants';
import { transferRightsConstants } from '../../constants/transferRightsConstants';
import { companyHasRight } from '../../utils/companyUtils';
import { isAuthorized, isFeatureActiveForPlatform } from '../../utils/userUtils';


export function canShowProjections(userProfile, summary) {
	const { companyProfile } = this.state;

	// hide if platform features does not allow
	if (
		!isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.PROJECTIONS,
			summary.platform_code,
			'view',
		)
	) {
		return false;
	}

	// hide if digital product
	if (summary.product_distribution_type === companyAgreementConstants.TYPE.DIGITAL) {
		return false;
	}

	// hide if user does not have authorization
	if (!isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.DETAIL.PROJECTIONS])) {
		return false;
	}

	// hide if user is not internal AND user's company cannot make projections
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PROJECTION.VIEW.ALL
	]);
	const canMakeProjections =
        companyProfile?.company_information &&
        companyProfile?.company_information.projection_eligible_flag === 1;
	if (!isInternal && !canMakeProjections) {
		return false;
	}

	// show if user is authorized with product.view.all.third perm
	let companyCheck = true;
	if (!isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.ALL.THIRD])) {
		// show if retail dist company for product matches user's company
		const retailDistributionCompany = summary.distribution.find(
            (distribution) => {
            	return distribution.right_type ===
                    transferRightsConstants.DISTRIBUTION_TYPE.RETAIL;
            }
		);
		companyCheck =
            retailDistributionCompany &&
            userProfile.companyId ===
                retailDistributionCompany.ndid_company_id;
	}

	return companyCheck;
}

export function canShowCodeInBoxAssets(userProfile, product) {
	const hasRights = (isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.CODEINBOX.VIEW.COMPANY,
	]) &&
		(companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
		) ||
			companyHasRight(
				userProfile.companyId,
				product.distribution,
				transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP,
			)));

	return (
		!!product &&
		!['WUP', 'RVL', 'CTR', 'KTR'].includes(product.platform_code) && // TODO: may be unnecessary
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.CODE_IN_BOX,
			product.platform_code,
			'view',
		) &&
		(isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL]) ||
			hasRights)
	);
}
export function canShowPackagingAssets(userProfile, product) {
	const isPhysical =
		product &&
		product.distribution.find(
			(o) =>
				o.right_type ===
				transferRightsConstants.DISTRIBUTION_TYPE.RETAIL
		);
	const isExternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.PACKAGING.VIEW.COMPANY
	]);
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL
	]);

	return (
		isPhysical &&
		(isInternal || isExternal) &&
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.PACKAGING_ASSETS,
			product.platform_code,
			'view',
		)
	);
}

export function canShowMarketingAssets(userProfile, product) {
	const isProductOwner =
		product &&
		product.eligible_transfer_flags &&
		(product.eligible_transfer_flags.has_ownership ||
			product.eligible_transfer_flags.has_retail ||
			product.eligible_transfer_flags.has_digital);
	const isExternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.MARKETING.VIEW.COMPANY
	]);
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL
	]);

	return (isInternal || (isProductOwner && isExternal)) &&
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.MARKETING_ASSETS,
			product.platform_code,
			'view',
		);
}

export function canAddAssets(userProfile, product, category) {
	if (userProfile && product && category) {
		switch(category) {
			case assetConst.CATEGORY.MARKETING:
				return canAddMarketingAssets(userProfile, product);
			case assetConst.CATEGORY.PACKAGING:
				return canAddPackagingAssets(userProfile, product);
			case assetConst.CATEGORY.CODE_IN_BOX:
				return canAddCodeInBoxAssets(userProfile, product);
			default:
		}

	}
	return false;
}

function canAddMarketingAssets(userProfile, product) {
	return (
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.MARKETING_ASSETS,
			product.platform_code,
			'edit'
		) && isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.MARKETING.ADD.COMPANY])
	);
}

function canAddPackagingAssets(userProfile, product) {
	return (
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.PACKAGING_ASSETS,
			product.platform_code,
			'edit',
		) &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.PACKAGING.ADD.COMPANY]) &&
		companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.RETAIL,
			'edit',
		)
	);
}

function canAddCodeInBoxAssets(userProfile, product) {
	return (
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.CODE_IN_BOX,
			product.platform_code,
			'edit',
		) &&
		!['WUP', 'RVL', 'CTR', 'KTR'].includes(product.platform_code) &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.ADD.COMPANY]) &&
		companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
		)
	);
}

export function canShowPhysicalOrderChecklist(userProfile, summary) {
	// hide if digital product
	if (summary.product_distribution_type === companyAgreementConstants.TYPE.DIGITAL) {
		return false;
	}

	return isFeatureActiveForPlatform(userProfile, platformFeatures.PHYSICAL_ORDER, summary.platform_code, 'edit');
}
