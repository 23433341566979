export function cleanGameConfigurationValue(value: unknown) {
	return (value === 'true' || value === true) ? 'Yes' :
		(value === 'false' || value === false || value === 'no') ? 'No' :
			(value === null) ? '' : 
				value;
}

/**
 *  Game config fields example
 *
 *  'Platform':{
 *      'Return API Header Value':{
 *          'title': 'What to display in the front-end'
 *          'fields': {
 *              'Field1 name to display in the front-end': 'Field1 Return API field accessor name',
 *              'Field2 name to display in the front-end': 'Field2 Return API field accessor name'
 *          }
 *      }
 *  }
 *
 *
 */
export const gameConfigFields = {
	SWITCH: {
		CONTAINS_NORTH_AMERICA_ENGLISH: 'cdr_application_language_english-north-america',
		CONTAINS_UK_ENGLISH: 'cdr_application_language_english-uk',
		CONTAINS_NORTH_AMERICAN_FRENCH: 'cdr_application_language_french-north-america',
		CONTAINS_FRENCH: 'cdr_application_language_french',
		CONTAINS_NORTH_AMIERICA_SPANISH: 'cdr_application_language_spanish-north-america',
		CONTAINS_SPANISH: 'cdr_application_language_spanish',
		AMIIBO_COMPATIBLE: 'cdr_ais_fi_amiibo_support',
		SAVE_DATA_CLOUD_BACK_UP_SETTINGS: 'j7_1829',
		NINTENDO_SWITCH_PRO_CONTROLLER: 'cdr_ais_fi_nx_pro_controller',
		TV_MODE: 'cdr_ais_fi_nx_tv_mode',
		TABLETOP_MODE: 'cdr_ais_fi_nx_tabletop_mode',
		HANDHELD_MODE: 'cdr_ais_fi_nx_handheld_mode',
		NEX_MATCHMAKING: 'j7_297',
		IN_GAME_PURCHASES: 'cdr_ais_fi_commerce_items.service_items',
		INDEPENDENT_SERVER_MATCHMAKING: 'j7_350',
		AGE_RATING: 'age_rating',
		AGE_RATING_DESCRIPTORS: 'age_rating_descriptors',
	},
	'RVL': {
		Features: {
			title: 'Features',
			fields: {
				'Add On Content': 'AddOnContent',
				'Add On Content Description': 'Add On Content Description',
				'Disc Type': 'DiscType',
				Dolby: 'Dolby',
				'Message Board Support': 'MessageBoardSupport',
				'Mobiclip Support': 'MobiclipSupport',
				'Nintendo WiFi Connection': 'NintendoWiFiConnection',
				'Number Of Discs': 'NumberOfDiscs',
				'Number Of Players': 'NumberOfPlayers',
				'Progressive Scan': 'ProgressiveScan',
				'SD Card Support': 'SDCardSupport',
				'Wii Connect 24': 'WiiConnect24',
				'Wii System Memory User Blocks': 'WiiSystemMemoryUserBlocks',
			},
		},
		ControllerConfiguration: {
			title: 'Controller Configuration',
			fields: {
				'Balance Board Percent Play': 'BalanceBoardPercentPlay',
				'Classic Controller': 'ClassicController',
				'Game Cube Controller': 'GameCubeController',
				Headset: 'Headset',
				'Headset Library': 'HeadsetLibrary',
				Microphone: 'Microphone',
				'Mii Approval': 'MiiApproval',
				'Mii Usage': 'MiiUsage',
				'Motion Sensor': 'MotionSensor',
				'Motion Sensor Use': 'MotionSensorUse',
				'Number Of Remotes Used': 'NumberOfRemotesUsed',
				'Nunchuk Use': 'NunchukUse',
				'Other Headset Library': 'OtherHeadsetLibrary',
				Pointer: 'Pointer',
				Rumble: 'Rumble',
				'User Generated Content': 'UserGeneratedContent',
				'Wii Balance Board Use': 'WiiBalanceBoardUse',
				'Wii Motion Plus Use': 'WiiMotionPlusUse',
				'Wii Remote Memory': 'WiiRemoteMemory',
				'Wii Remote Speaker': 'WiiRemoteSpeaker',
				'Wii Remote Use': 'WiiRemoteUse',
				'Wii To DS Download': 'WiiRemoteUse',
			},
		},
	},
	'WUP': {
		Features: {
			title: 'Features',
			fields: {
				'Number of Discs': 'NumberOfDiscs',
				'Data Capacity Exceeds 32MB': 'DataCapacityExceed32MB',
				'Download Total Size Exceeds 512 MB': 'DownloadTotalSizeExceeds512MB',
				'Number of Players': 'NumberOfPlayers',
				'Principal StorageSize Exceeds 10 MB': 'PrincipalStorageSizeExceeds10MB',
				'SD Card Support': 'SDCardSupport',
				'Support Surround Sound': 'SupportSurroundSound',
				'Total Volume Storage Size Exceeds 100 MB': 'TotalVolumeStorageSizeExceeds100MB',
				'Utilize Autodesk Library': 'UtilizeAutodesklibrary',
				'Utilize Havok Library': 'UtilizeHavoklibrary',
				'Utilize Recognition Libraries': 'UtilizePanasonicRecognitionlibraries',
				'Utilize QR Code Libraries': 'UtilizeQRCodelibraries',
				'Wii U Pedometer': 'WiiUPedometer',
			},
		},
		WiiControllerFeatures: {
			title: 'Wii Controller Features',
			fields: {
				'Balance Board Percent Play': 'BalanceBoardPercentPlay',
				Headset: 'Headset',
				'Headset Library': 'HeadsetLibrary',
				Microphone: 'Microphone',
				'Motion Sensor': 'MotionSensor',
				'Motion Sensor Use': 'MotionSensorUse',
				'Number Of Remotes Used': 'NumberOfRemotesUsed',
				'Nunchuk Use': 'NunchukUse',
				'Other Headset Library': 'OtherHeadsetLibrary',
				Pointer: 'Pointer',
				Rumble: 'Rumble',
				'Wii Balance Board Use': 'WiiBalanceBoardUse',
				'Wii Classic Controller': 'WiiClassicController',
				'Wii Motion Plus Use': 'WiiMotionPlusUse',
				'Wii Remote Memory': 'WiiRemoteMemory',
				'Wii Remote Speaker': 'WiiRemoteSpeaker',
			},
		},
		GamePadInfo: {
			title: 'Wii U GamePad Features',
			fields: {
				'Accelerometer Gyro': 'AccelerometerGyro',
				'Accelerometer Gryo Description': 'AccelerometerGyroDescription',
				'Camera Description': 'CameraDescription',
				'Game Pad Camera': 'GamePadCamera',
				'Game Pad Microphone': 'GamePadMicrophone',
				'Microphone Description': 'MicrophoneDescription',
				'Touch Screen': 'TouchScreen',
				'Touch Screen Description': 'TouchScreenDescription',
				'Wii U Game Pad Support': 'WiiUGamePadSupport',
				'Wii U Game Pad Support Description': 'WiiUGamePadSupportDescription',
			},
		},
		GamePadUsageStyle: {
			title: 'Wii U GamePad Usage Style',
			fields: {
				'Other Usage That May Indicate Safety Concerns Description':
					'OtherUsageThatMayIndicateSafetyConcernsDescription',
				'Other Usage That May Indicate Safety Concerns Such As Style And Movement Or Rapid Movements In General':
					'OtherUsageThatMayIndicateSafetyConcernsSuchAsStyleAndMovementOrRapidMovementsInGeneral',
				'User Holds Or Moves The Game Pad Above Their Head':
					'UserHoldsOrMovesTheGamePadAboveTheirHead',
				'User Holds Or Moves The Game Pad Above Their Head Description':
					'UserHoldsOrMovesTheGamePadAboveTheirHeadDescription',
				'User Places The Game Pad On The Floor And Uses It In That Position':
					'UserPlacesTheGamePadOnTheFloorAndUsesItInThatPosition',
				'User Places The Game Pad On The Floor Description':
					'UserPlacesTheGamePadOnTheFloorDescription',
				'User Turns Around Quickly While Holding The Game Pad':
					'UserTurnsAroundQuicklyWhileHoldingTheGamePad',
				'User Turns Around Quickly While Holding The Game Pad Description':
					'UserTurnsAroundQuicklyWhileHoldingTheGamePadDescription',
				'User Uses Game Pad And Wii Balance Board At The Same Time':
					'UserUsesGamePadAndWiiBalanceBoardAtTheSameTime',
				'User Uses Game Pad And Wii Balance Board At The Same Time Description':
					'UserUsesGamePadAndWiiBalanceBoardAtTheSameTimeDescription',
			},
		},
		Nintendo3DSCommunication: {
			title: 'Nintendo 3DS Communication',
			fields: {
				'Associated Nintendo 3DS Title And Game Code':
					'AssociatedNintendo3DSTitleAndGameCode',
				'Communication With Nintendo 3DS Title': 'CommunicationWithNintendo3DSTitle',
				'Communication With Nintendo 3DS Title Description':
					'CommunicationWithNintendo3DSTitleDescription',
				'Wii U To Nintendo 3DS Download': 'WiiUToNintendo3DSDownload',
			},
		},
		OnlineFeatures: {
			title: 'Online Features',
			fields: {
				'Consumable Additional Online Content': 'ConsumableAdditionalOnlineContent',
				'Consumable Additional Online Content Description':
					'ConsumableAdditionalOnlineContentDescription',
				'Mii Approval': 'MiiApproval',
				'Mii Usage': 'MiiUsage',
				'Online Commerce Additional Online Contents Microtransactions':
					'OnlineCommerceAdditionalOnlineContentsMicrotransactions',
				'Online Commerce Description': 'OnlineCommerceDescription',
				'Online Multi Play': 'OnlineMultiPlay',
				'Online Multi Play Description': 'OnlineMultiPlayDescription',
				'Other Online Functionality': 'OtherOnlineFunctionality',
				'Other Online Functionality Description': 'OtherOnlineFunctionalityDescription',
				'Plan For Patching': 'PlanForPatching',
				'Plan For Patching Description': 'PlanForPatchingDescription',
				'Rich User Generated Content': 'RichUserGeneratedContent',
				'Rich User Generated Content Description': 'RichUserGeneratedContentDescription',
				'User Generated Content Simple': 'UserGeneratedContentSimple',
				'User Generated Content Simple Description':
					'UserGeneratedContentSimpleDescription',
			},
		},
		MiiverseFeatures: {
			title: 'Miiverse Features',
			fields: {
				'Message Board Player To Community': 'MessageBoardPlayerToCommunity',
				'Message Board Player To Community Description':
					'MessageBoardPlayerToCommunityDescription',
				'Message Board Player To Player': 'MessageBoardPlayerToPlayer',
				'Message Board Player To Player Description':
					'MessageBoardPlayerToPlayerDescription',
				'Miiverse Integration': 'MiiverseIntegration',
				'Miiverse Integration Description': 'MiiverseIntegrationDescription',
				'Miiverse Message Board Support': 'MiiverseMessageBoardSupport',
				'Miiverse Message Board Support Description':
					'MiiverseMessageBoardSupportDescription',
				'Private Community Support': 'PrivateCommunitySupport',
				'Private Community Support Description': 'PrivateCommunitySupportDescription',
				'Sharing Of User Generated Content In The Miiverse':
					'SharingOfUserGeneratedContentInTheMiiverse',
				'Sharing Of User Generated Content In The Miiverse Description':
					'SharingOfUserGeneratedContentInTheMiiverseDescription',
			},
		},
	},
	'CTR': {
		StorageSpecifications: {
			title: 'Storage Specifications',
			fields: {
				'Backup Memory Size': 'BackupMemorySize',
				'Backup MemoryType Is Flash': 'BackupMemoryTypeIsFlash',
				'Program Size In Bytes': 'ProgramSizeInBytes',
				'System Blocks Used': 'SystemBlocksUsed',
			},
		},
		FeaturesUsed: {
			title: 'Features Used',
			fields: {
				'Accelerometer Use': 'AccelerometerUse',
				CEPD: 'CEPD',
				'Camera Support': 'CameraSupport',
				'Decuma Handwriting': 'DecumaHandwriting',
				GGID: 'GGID',
				'IR Port Usage': 'IRPortUsage',
				'LC Font': 'LCFont',
				'Lite Speech': 'LiteSpeech',
				'Microphone Support': 'MicrophoneSupport',
				'Mobiclip Support': 'MobiclipSupport',
				'Multi Card Players': 'MultiCardPlayers',
				'Multi Card Support': 'MultiCardSupport',
				'Net Front Browser': 'NetFrontBrowser',
				'Nintendo Network': 'NintendoNetwork',
				'One Time ROM': 'OneTimeROM',
				'SD Card Support': 'SDCardSupport',
				'Single Card Players': 'SingleCardPlayers',
				'Single Card Support': 'SingleCardSupport',
				'Stereoscopic 3D': 'Stereoscopic3D',
				'Street Pass': 'StreetPass',
				'Street Pass Description': 'StreetPassDescription',
				'Touch Screen': 'TouchScreen',
			},
		},
		WiiUCommunication: {
			title: 'Wii U Communication',
			fields: {
				'Associated Wii U Title And GameCode': 'AssociatedWiiUTitleAndGameCode',
				'Communication With Wii U Title': 'CommunicationWithWiiUTitle',
				'Communication With Wii U Title Description':
					'CommunicationWithWiiUTitleDescription',
			},
		},
		OnlineFeatures: {
			title: 'Online Features',
			fields: {
				'Consumable Additional Online Contents': 'ConsumableAdditionalOnlineContents',
				'Consumable Additional Online Contents Description':
					'ConsumableAdditionalOnlineContentsDescription',
				'Mii Usage': 'MiiUsage',
				'Online Commerce Additional Online Contents Microtransactions':
					'OnlineCommerceAdditionalOnlineContentsMicrotransactions',
				'Online Commerce Description': 'OnlineCommerceDescription',
				'Online Multi Play': 'OnlineMultiPlay',
				'Online Multi Play Description': 'OnlineMultiPlayDescription',
				'Other Online Functionality': 'OtherOnlineFunctionality',
				'Other Online Functionality Description': 'OtherOnlineFunctionalityDescription',
				'Plan For Patching': 'PlanforPatching',
				'Plan For Patching Description': 'PlanforPatchingDescription',
				'Rich User Generated Content': 'RichUserGeneratedContent',
				'Rich User Generated Content Description': 'RichUserGeneratedContentDescription',
				'Spot Pass': 'SpotPass',
				'Spot Pass Description': 'SpotPassDescription',
				'User Generated Content Simple': 'UserGeneratedContentSimple',
				'User Generated Content Simple Description':
					'UserGeneratedContentSimpleDescription',
			},
		},
	},
};
