import React, {
	HTMLAttributes,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	VFC,
} from 'react';

import './FAIcon.css';

interface FAIconProps extends HTMLAttributes<HTMLSpanElement> {
	name: string;
	size?: 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
	fixed?: boolean;
	variant?: 'solid' | 'regular' | 'light' | 'duotone' | 'brands';
	stack?: '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
	alt?: ReactNode;
}

type FAIconSubcomponents = {
	Stack: typeof Stack;
};

const FAIcon: VFC<FAIconProps> & FAIconSubcomponents = (props: FAIconProps): ReactElement => {
	const { className, variant, size, stack, fixed, name, alt } = props;
	const classes: Array<string> = ['FAIcon'];
	if (className) {
		classes.push(className);
	}
	if (variant && ['solid', 'regular', 'light', 'duotone', 'brands'].includes(variant)) {
		classes.push('fa' + variant.charAt(0));
	} else {
		classes.push('fa');
	}

	size &&
		['xs', 'sm', 'lg', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].includes(size) &&
		classes.push('fa-' + size);
	stack &&
		['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].includes(stack) &&
		classes.push('fa-stack-' + stack);
	fixed && classes.push('fa-fw');
	classes.push('fa-' + name);

	const passedProps: Record<string, any> = { ...props };
	delete passedProps['name'];
	delete passedProps['size'];
	delete passedProps['fixed'];
	delete passedProps['variant'];
	delete passedProps['stack'];
	delete passedProps['alt'];
	return (
		<i
			{...passedProps}
			aria-hidden={props['aria-hidden'] || 'true'}
			className={classes.join(' ')}
			data-alt={alt}
		/>
	);
};

interface StackProps extends HTMLAttributes<HTMLSpanElement> {
	size?:
		| 'xs'
		| 'sm'
		| 'lg'
		| '2x'
		| '3x'
		| '4x'
		| '5x'
		| '6x'
		| '7x'
		| '8x'
		| '9x'
		| '10x'
		| undefined;
}

const Stack: VFC<PropsWithChildren<StackProps>> = (props: StackProps): ReactElement => {
	const classes = ['fa-stack'];
	props.size &&
		['xs', 'sm', 'lg', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].includes(
			props.size,
		) &&
		classes.push('fa-' + props.size);
	return <span {...props} className={classes.join(' ')} />;
};

FAIcon.Stack = Stack;

export default FAIcon;

// Font Awesome v5 resource:
//
// docs: https://fontawesome.com/v5/docs
// cheatsheet: https://fontawesome.com/v5/cheatsheet/pro
