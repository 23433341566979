import React, { useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { platformFeatures } from '../../../constants/platformConstants';
import { isEmptyObject } from '../../../utils/dataUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { toasterNotify } from '../../../utils/toaster';
import { postHelpTopic, putHelpTopic } from '../../../services/helpTopicsService';
import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { useReferenceData, useUserProfile } from '../../../hooks/reduxHooks';
import { doAllFilesHaveExtension } from '../../../utils/assetUtils';
import { getAvailableFeaturePlatforms } from '../../../utils/userUtils';

const DEFAULT = 'default';

interface FormValues {
	help_topic_id?: number;
	name?: string;
	active?: boolean;
	roles?: string[];
	platform_code?: PlatformCode | 'default';
}

export const baseSchema = yup.object({
	name: yup.string().trim().default('').required('Help Topic must have a name'),
	active: yup.boolean().default(false),
	role_names: yup.array().nullable().default([]),
	platform_code: yup.string().nullable().default(null),
});

const SaveHelpTopicModal = ({
	show,
	onCompletion,
	onClose,
	editPermission,
	roleNames,
	helpTopic,
}: {
	show: boolean;
	onCompletion: () => void;
	onClose: () => void;
	editPermission: boolean;
	roleNames: string[];
	helpTopic?: Record<string, any>;
}) => {
	const [entityId, setEntityId] = useState<string | null>(null);
	const [fileSelected, setFileSelected] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [showFileValidationError, setShowFileValidationError] = useState<boolean>(false);
	const [validFileSelected, setValidFileSelected] = useState<boolean>(false);

	const userProfile = useUserProfile();
	const validationErrors = validateToSchema(baseSchema, formValues);
	const referenceDataPlatforms = useReferenceData('platforms');
	const availablePlatformCodes = useMemo(() => {
		return getAvailableFeaturePlatforms(userProfile, platformFeatures.HELP_TOPIC, 'view');
	}, [userProfile, referenceDataPlatforms]);

	useEffect(() => {
		if (show) {
			setEntityId(helpTopic ? helpTopic.help_topic_id.toString() : null);
			setFileSelected(false);
			setFormValues(helpTopic ? helpTopic : {});
			setIsLoading(false);
			setIsSubmitting(false);
			setShowAllErrors(false);
			setShowFileValidationError(false);
			setValidFileSelected(false);
		}
	}, [show]);

	const validateFileType = (selectedFiles: AsperaFile[]) => {
		const allowedFileTypes = allowedFileExtensions.HELP_TOPIC;
		const isValid = doAllFilesHaveExtension(selectedFiles, allowedFileTypes);
		setValidFileSelected(isValid);
		setShowFileValidationError(false);
	};

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		else if ((!fileSelected && !helpTopic) || (fileSelected && !validFileSelected)) {
			setShowFileValidationError(true);
			return;
		}

		const castedFormValues = baseSchema.cast(formValues, { stripUnknown: true });
		setIsSubmitting(true);

		try {
			if (helpTopic) {
				await putHelpTopic(helpTopic?.help_topic_id, castedFormValues);
				if (!fileSelected) {
					onClose();
				}
			} else {
				const response = await postHelpTopic(castedFormValues);
				setEntityId(response.data.help_topic_id.toString());
			}
			onCompletion();
			toasterNotify(
				`Your help topic "${castedFormValues.name}" has been successfully saved.`,
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				'There was an unexpected error while saving the help topic.',
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	return (
		<BaseModal show={show} onCancel={onClose} isLoading={isLoading} isSubmitting={isSubmitting}>
			<BaseModal.Title>
				{helpTopic ? `Edit ${helpTopic?.name}` || 'Edit Help Topic' : 'Add Help Topic'}
			</BaseModal.Title>
			<Forms
				onChange={(v) => {
					v['platform_code'] = v.platform_code === DEFAULT ? null : v.platform_code;
					setFormValues({
						...v,
					});
				}}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.Text id="name" disabled={!editPermission}>
					<Forms.Heading>Name</Forms.Heading>
				</Forms.Text>
				<Forms.CustomArea>
					<Forms.Heading>File</Forms.Heading>
					<AsperaFileUpload
						userProfile={userProfile}
						entityType={fileTransferConst.ENTITY_TYPE.HELP_TOPIC}
						entityId={entityId}
						allowedFileTypes={[
							{
								filter_name: 'Accepted Files',
								extensions: allowedFileExtensions.HELP_TOPIC,
							},
						]}
						isUploadInitiated={
							helpTopic
								? show && isSubmitting && fileSelected
								: show && isSubmitting && !!entityId
						}
						validateFileType={(files: AsperaFile[]) => validateFileType(files)}
						updateFilesSelected={(isFileSelected: boolean) =>
							setFileSelected(isFileSelected)
						}
						onCompleteFunction={() => {
							onClose();
						}}
						singleFileOnly
					/>
					{showFileValidationError && (
						<Forms.ErrorBlock>
							<span className="text-danger">
								A .PDF or .ZIP file must be selected to continue.
							</span>
						</Forms.ErrorBlock>
					)}
				</Forms.CustomArea>
				<Forms.SingleCheckbox id={'active'}>
					<Forms.Heading>Active</Forms.Heading>
				</Forms.SingleCheckbox>

				<Forms.CheckboxList id="role_names" disabled={!editPermission}>
					<Forms.Heading>Role(s)</Forms.Heading>

					{roleNames?.map((roleName) => (
						<Forms.Option key={roleName} value={roleName}>
							{roleName}
						</Forms.Option>
					))}
				</Forms.CheckboxList>
				<Forms.Select id={'platform_code'}>
					<Forms.Heading>Platform</Forms.Heading>
					<Forms.Help>Optional</Forms.Help>
					<Forms.Option value={DEFAULT}><i>None</i></Forms.Option>
					{availablePlatformCodes && referenceDataPlatforms &&
						availablePlatformCodes.map((platform_code) => (
							<Forms.Option
								value={platform_code}
								key={platform_code}
							>
								{referenceDataPlatforms.find(
									(p: PlatformData) => p.platform_code === platform_code)?.platform_name}
							</Forms.Option>
						))}
				</Forms.Select>
			</Forms>

			{editPermission && (
				<BaseModal.Submit
					onClick={() => {
						submitForm();
					}}
				>
					{helpTopic ? 'Update' : 'Add'}
				</BaseModal.Submit>
			)}
		</BaseModal>
	);
};

export default SaveHelpTopicModal;
