import React, { VFC, useContext, useMemo } from 'react';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import { getDataFormat, getFilterProperties, getPermissions } from '../PhysicalOrdersManagement.helpers';
import { useReferenceData, useUserProfile } from '../../../hooks/reduxHooks';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading/Loading';
import { PhysicalOrderIndexData, getPhysicalOrders } from '../../../services/ordersService';
import { pullAllResults } from '../../../utils/serviceUtils';
import { mapToDisplayedStatus } from '../../../utils/orderUtils';
import Page from '../../../components/Page/Page';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';


interface OrdersTableTabProps {
    eventKey: string;
    activePlatforms?: boolean;
}
const OrdersTableTab:VFC<OrdersTableTabProps> = ({ eventKey, activePlatforms }) => {
	const userProfile = useUserProfile();
	const platforms = useReferenceData('platforms');
	const show = useContext(TabState)?.tab === eventKey;
	const { canViewAllOrders } = getPermissions(userProfile);
	const ordersType = activePlatforms ? 'active' : 'legacy';
	const ordersQuery = useQuery(
		['pullAllResults:getPhysicalOrders', 8000, { platform_status: ordersType }],
		() => pullAllResults<PhysicalOrderIndexData>(getPhysicalOrders, 8000, {
			platform_status: ordersType,
		}),
		{ enabled: show },
	);
	const dataFormat = useMemo(() => getDataFormat(userProfile), [userProfile]);
	const filterProperties = useMemo(
		() =>
			userProfile && getFilterProperties(userProfile, platforms, activePlatforms),
		[userProfile, activePlatforms],
	);
	const orders = (ordersQuery.isSuccess && ordersQuery.data) || undefined;
	const ordersWithDisplayStatus = orders?.map((order) => {
		return {...order, displayStatus: mapToDisplayedStatus(order, userProfile)};
	});
	const searchableFields = canViewAllOrders
		? ['noa_po', 'publisher_po', 'publisher', 'req_number', 'noa_sales_order_number']
		: ['publisher_po'];
	const searchableFieldPlaceHolder = canViewAllOrders
		? 'Search Within...'
		: 'Search by Publisher PO...';
        
	return <Page.ContentContainer>{ ordersQuery.isLoading ? (
        <Loading />
	) : (
        
        <FilterableTable
            dataFormat={dataFormat}
            data={ordersWithDisplayStatus}
            filterProperties={filterProperties}
            searchableFields={searchableFields}
            searchableFieldPlaceHolder={searchableFieldPlaceHolder}
            defaultSorted={[
                {
                    id: 'submission_date',
                    desc: true,
                },
            ]}
            noBorder
            retainPageState
            stateOnTab={eventKey}
        />)
        }</Page.ContentContainer>;
};

export default OrdersTableTab;
