import React from 'react';

import { isAuthorizedForUrl } from '../../utils/userUtils';
import NotFound from '../../containers/NotFound/NotFound';
import { useUserProfile } from '../../hooks/reduxHooks';

export const RouterGate = ({ props, Component }) => {
	const userProfile = useUserProfile();
	return isAuthorizedForUrl(userProfile, props.match.path) ? (
		<Component {...props} />
	) : (
		<NotFound />
	);
};
