import React, { VFC } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';

import Loading from '../../../components/Loading/Loading';
import { getProductGameConfiguration, getProductGameFeatures } from '../../../services/productsService';
import FAIcon from '../../../components/FAIcon/FAIcon';
import GameConfiguration from '../views/GameConfiguration';
import SwitchGameConfiguration from '../views/SwitchGameConfiguration';
import GameFeatures from '../views/GameFeatures';
import { useReferenceData } from '../../../hooks/reduxHooks';


const legacyPlatforms: unknown[] = ['RVL', 'WUP', 'CTR', 'KTR', 'HAC'];

interface GameConfigTabProps {
	isSelected?: boolean;
	product?: ProductData;
}
const GameConfigTab: VFC<GameConfigTabProps> = ({ isSelected, product }) => {
	const platforms = useReferenceData('platforms');
	const { platform_code, product_id } = product ?? {};
	const onLegacyPlatform = legacyPlatforms.includes(platform_code);
	const configQuery = useQuery(
		['getProductGameConfiguration', product_id],
		() => getProductGameConfiguration(String(product?.product_id)),
		{ enabled: !!isSelected && !!product_id && onLegacyPlatform },
	);
	const gameConfiguration = configQuery.isSuccess && configQuery.data.data;

	const featuresQuery = useQuery(
		['getProductGameFeatures', product_id],
		() => getProductGameFeatures(String(product_id)),
		{ enabled: !!isSelected && !!product_id && !onLegacyPlatform },
	);

	return (!isSelected || !product || !platform_code) ? (
		null
	) : configQuery.isLoading || featuresQuery.isLoading  ? (
		<Loading />
	) : configQuery.isError || featuresQuery.isError ? (
		<Alert variant="danger">
			There was an issue loading game configuration data.{' '}
			<a onClick={(e) => configQuery.refetch()}>Retry load</a>
			<FAIcon size="xs" name="chevron-right" />
		</Alert>
	) : product.platform_code === 'HAC' ? (
		<SwitchGameConfiguration gameConfiguration={gameConfiguration} />
	) : legacyPlatforms.includes(platform_code) ? (
		<GameConfiguration
			gameConfiguration={gameConfiguration}
			systemFamily={platforms?.find((p: PlatformData) => p.platform_code === platform_code)?.system_family}
		/>
	) : product_id ? (
		<GameFeatures productId={product_id} />
	) : null;
};

export default GameConfigTab;
