import React, { VFC } from 'react';
import ReactSelect, {
	components,
	OptionProps,
	Props,
	SingleValueProps,
	StylesConfig,
} from 'react-select';

import FAIcon from '../FAIcon/FAIcon';

import './DropdownSelect.css';


const defaultStyles: StylesConfig = {
	multiValue: (styles, { data }: { data: any }) => {
		return {
			...styles,
			backgroundColor: data.color ? data.color : 'white',
			color: data.color ? data.color : 'black',
			borderWidth: data.color ? 0 : 1,
			borderColor: 'hsl(0, 0%, 80%)',
			borderStyle: 'solid',
		};
	},
	multiValueLabel: (styles, { data }: { data: any }) => ({
		...styles,
		color: data.color ? data.color : undefined,
		whiteSpace: 'normal',
	}),
	multiValueRemove: (styles, props) => ({
		...styles,
		color: 'hsl(0, 0%, 80%)',
	}),
	option: (styles, { data, isDisabled, isSelected }) => ({
		...styles,
		...(!isSelected &&
		typeof data === 'object' &&
		!!data &&
		'variant' in data &&
		data?.variant === 'danger'
			? { color: 'var(--danger)' }
			: null),
		...(isDisabled ? { opacity: '0.8' } : null),
	}),
	singleValue: (styles, { data, isDisabled }: { data: any; isDisabled: boolean }) => ({
		...styles,
		...(data?.variant === 'danger' ? { color: 'var(--danger)' } : null),
	}),
};

const CustomOption: VFC<OptionProps<unknown>> = ({ children, ...props }) => {
	const footnote =
		props.data &&
		typeof props.data === 'object' &&
		'footnote' in props.data &&
		(props.data.footnote as string);
	const variant =
		props.data &&
		typeof props.data === 'object' &&
		'variant' in props.data &&
		(props.data.variant as string);
	return (
		<components.Option {...props}>
			{children}
			{footnote && (
				<div
					className={'m-0' + variant === 'danger' ? ` text-${variant}` : ''}
					style={{ marginTop: '0rem' }}
				>
					<small className={`text-${variant}`}>
						{variant === 'danger' && <FAIcon name="exclamation-circle" />}
						{variant === 'warning' && <FAIcon name="exclamation-triangle" />}
						{variant === 'info' && <FAIcon name="info-circle" />} {footnote}
					</small>
				</div>
			)}
		</components.Option>
	);
};

const CustomSingleValue: VFC<SingleValueProps<unknown>> = ({ children, ...props}) => {
	const variant =
		props.data &&
		typeof props.data === 'object' &&
		'variant' in props.data &&
		(props.data.variant as string);
	return <components.SingleValue {...props}>
		{variant === 'danger' && <><FAIcon name="exclamation-circle" /> </>}
		{children}
	</components.SingleValue>;
};

const DropdownSelect: VFC<Props> = (props: Props) => {
	return (
		<ReactSelect
			className="DropdownSelect"
			classNamePrefix="DropdownSelect"
			styles={defaultStyles}
			components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
			{...props}
		/>
	);
    
};
export default DropdownSelect;
