import React, { VFC} from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import ActionLink from '../../../components/ActionLink/ActionLink';
import BaseTable, { linkColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { companyAgreementConstants } from '../../../constants/companyAgreementConstants';
import { permConst } from '../../../constants/permConst';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { platformFeatures } from '../../../constants/platformConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getDigitalCatalog } from '../../../services/digitalCodesService';
import { formatCurrency } from '../../../utils/currencyUtils';
import { compareDates } from '../../../utils/dateUtils';
import { getCurrentRequest } from '../../../utils/digitalCodesUtils';
import { isAuthorized, isAuthorizedForUrl, isFeatureActiveForPlatform } from '../../../utils/userUtils';
import StatusText from '../../../components/StatusText/StatusText';
import { useCompanyAgreementsQuery } from '../../../hooks/queryHooks';

const DUPLICATE = '__DUPLICATE__';


interface DigitalCatalogTabProps {
	productId?: number;
	product: {
		agreement_family: string;
		platform_code: PlatformCode;
	};
	isSelected: boolean;
	flagIssue: () => void;
	rightsHolders: ProductData['distribution'];
}

interface PriceStatusProps {
	price: string;
	status: string;
}

const DigitalCatalogTab: VFC<DigitalCatalogTabProps> = ({ productId, product, isSelected, flagIssue, rightsHolders }: DigitalCatalogTabProps) => {
	const userProfile = useUserProfile();

	const catalogQuery = useQuery(
		['getDigitalCatalog', productId],
		() => getDigitalCatalog(String(productId)),
		{
			onSuccess: ({ data }) => {
				if (data?.some((item) => item.retail_prices?.length > 1)) {
					flagIssue();
				}
			},
		},
	);

	const fault = catalogQuery.isError && catalogQuery.error;

	const digitalNDIDCompanyId = rightsHolders?.find((rh) => rh.right_type
		=== transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL)?.ndid_company_id;
	const ownerNDIDCompanyId = rightsHolders?.find((rh) => rh.right_type
		===	transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP)?.ndid_company_id;
	const isDigitalPub = isAuthorized(userProfile.permissions,
		[
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.VIEW.COMPANY,
			permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.VIEW.COMPANY,
		])
		&& userProfile.companyId === digitalNDIDCompanyId;
	const isOwnerOnly = isAuthorized(userProfile.permissions,
		[
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.VIEW.COMPANY,
			permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.VIEW.COMPANY,
		])
		&& userProfile.companyId !== digitalNDIDCompanyId
		&& userProfile.companyId === ownerNDIDCompanyId;

	const agreementsQuery = useCompanyAgreementsQuery({
		enabled: isAuthorized(userProfile.permissions, [permConst.PRODUCT.COMPONENT.VIEW.COMPANY]) &&
			(isDigitalPub || isOwnerOnly)
	});

	if (!isSelected) {
		return null;
	}

	const canSeeWholesalePrice = product != null && isFeatureActiveForPlatform(
		userProfile,
		platformFeatures.CODE_IN_BOX,
		product.platform_code,
		'view',
	);

	const agreementsAreOk = (agreements?: CompanyAgreement[]) =>
		agreements?.some(
			(agreement) =>
				agreement.agreementType === companyAgreementConstants.TYPE.DIGITAL &&
				product.agreement_family === agreement.platformDevelopmentCode &&
				(!agreement.expirationDate || compareDates(agreement.expirationDate) > 0) &&
				[
					companyAgreementConstants.STATUS.EXECUTED,
					companyAgreementConstants.STATUS.IN_NEGOTIATION,
				].includes(agreement.status),
		);
	const allowLinkToComponentDetail =
		isAuthorizedForUrl(userProfile, '/products/components/:nsUid') &&
		// user can view all
		(isAuthorized(userProfile.permissions, [permConst.PRODUCT.COMPONENT.VIEW.ALL]) ||
			// or user is the digital publisher or owner and has agreements
			(isAuthorized(userProfile.permissions, [permConst.PRODUCT.COMPONENT.VIEW.COMPANY]) &&
				(isDigitalPub || isOwnerOnly) &&
				agreementsAreOk(agreementsQuery.data?.data)));

	const isLoading = catalogQuery.isLoading || agreementsQuery.isLoading;


	const tableFormat = [
		{ Header: 'NSUID', accessor: 'ns_uid', width: 150 },
		{ Header: '', accessor: 'game_name' },
		{
			Header: 'Retail Price',
			id: 'retail_price',
			accessor: ({ retail_prices, declared_price_requests }:
						   { retail_prices: Record<string, any>[], declared_price_requests: [] }) => {
				if (retail_prices && retail_prices?.length > 1) {
					return { status: DUPLICATE };
				} else if (retail_prices && retail_prices.length > 0 && retail_prices.length < 2) {
					return {
						status: 'ACTIVE',
						price: retail_prices[0].price
					};
				}

				const currentRequest = getCurrentRequest(declared_price_requests);
				if (currentRequest) {
					return { price: currentRequest?.rollup_status === 'IN PROCESS' ? undefined : currentRequest.certified_price,
						     status: currentRequest?.rollup_status === 'IN PROCESS' ? 'IN PROCESS' : 'DECLARED' };
				} else {
					return null;
				}
			},
			sortMethod: (a: PriceStatusProps, b: PriceStatusProps) => {
				return parseFloat(a?.price === undefined ? '0' : a?.price) >
				       parseFloat(b?.price === undefined ? '0' : b?.price) ? 1 : -1;
			},
			Cell: ({ value }: { value: PriceStatusProps | null }) => {
				if (!value) {
					return (<></>);
				} else if (value?.status === DUPLICATE) {
					return (<b style={{ color: 'red' }}>Duplicate</b>);
				} else if (value.status === 'ACTIVE') {
					return (formatCurrency(value.price));
				} else if (value.status === 'IN PROCESS') {
					return (<div><StatusText variant="warning" badge>IN PROCESS</StatusText></div>);
				} else if (value.status === 'DECLARED') {
					return (<div>{formatCurrency(value.price)}  <StatusText badge>{value.status}</StatusText></div>);
				} else {
					return (<></>);
				}
			},
			width: 160,
		},
		...(canSeeWholesalePrice
			? [
				{
					Header: (
						<>
							Wholesale Price
						</>
					),
					id: 'wholesale_price',
					accessor: ({retail_prices, wholesale_price_requests}: { retail_prices: [], wholesale_price_requests: [] }) => {
						if (retail_prices?.length > 1) {
							return null;
						}
						const currentRequest = getCurrentRequest(wholesale_price_requests);
						return currentRequest;
					},
					sortMethod: (a: WholesalePriceRequestData, b: WholesalePriceRequestData) => {
						return parseFloat(a?.certified_price === undefined ? '0' : a?.certified_price) >
						parseFloat(b?.certified_price === undefined ? '0' : b?.certified_price) ? 1 : -1;
					},
					Cell: ({row, value}: { row: Record<string, any>, value: WholesalePriceRequestData }) => {
						if (!value) {
							return null;
						}

						if (value?.rollup_status === 'IN PROCESS') {
							return (<div><StatusText variant="warning" badge>IN PROCESS</StatusText></div>);
						} else {
							return (value && formatCurrency(value?.certified_price));
						}
					},
					width: 180,
				}
			]
			: []),
		{
			id: 'retractable',
			accessor: ({ retail_prices, wholesale_price_requests }: { retail_prices: [], wholesale_price_requests: [] }) => {
				if (retail_prices?.length > 1) {
					return null;
				}
				const currentRequest = getCurrentRequest(wholesale_price_requests);
				return currentRequest?.retractable || null;
			},
			show: false,
		},
		...(allowLinkToComponentDetail
			? [
				{
					...linkColumn,
					Cell: ({ original }: { original: { ns_uid: string } }) => (
							<Link to={`/products/components/${original.ns_uid}`}>
								<FAIcon name="chevron-right" />
							</Link>
					),
				},
			  ]
			: []),
	];

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<SectionTitle>Digital Catalog</SectionTitle>
					{fault ? (
						<Alert variant="danger">
							There was an error while loading digital catalog data, which is
							necessary for this tab.{' '}
							<ActionLink
								onClick={() => catalogQuery.refetch({ cancelRefetch: true })}
							>
								Retry load
							</ActionLink>
							<FAIcon size="xs" name="chevron-right" />
						</Alert>
					) : !catalogQuery?.data?.data?.length ? (
						<Alert variant="warning">No digital items were found.</Alert>
					) : (
						catalogQuery.isSuccess && (
							<>
								<BaseTable
									className="mb-3"
									columns={tableFormat.map((column) =>
										column.accessor === 'game_name'
											? { ...column, Header: 'Main Title' }
											: column,
									)}
									data={catalogQuery.data?.data.filter(
										(x) => x.content_type === 'TITLE',
									)}
									showPagination={false}
									defaultPageSize={catalogQuery.data?.data.length}
									sortable={false}
									allowOverflow
								/>
								{catalogQuery.data?.data.some(
									(x) => x.content_type !== 'TITLE',
								) && (
									<BaseTable
										className="mb-3"
										columns={tableFormat.map((column) =>
											column.accessor === 'game_name'
												? { ...column, Header: 'Component' }
												: column,
										)}
										data={catalogQuery.data?.data.filter(
											(x) => x.content_type !== 'TITLE',
										)}
										showPagination={catalogQuery.data?.data.length > 10}
										allowOverflow
									/>
								)}
							</>
						)
					)}
					<div className="digital-codes-note">
						<p>
							Your product that can be ordered as digital codes are listed on this
							tab.
						</p>

						<p>
							If this page is blank the NCMS entry for this product has not progressed
							far enough for codes to be ordered through the system.
						</p>

						<p>
							Items indicated as <b>Duplicate</b> have a pricing error. The pricing
							error will need to be resolved before codes can be ordered. Please
							contact us to correct the error.
						</p>

						<p>
							Digital items must have a permanent price entry to be ordered. Price
							entries in NCMS will appear on this page the day after the price goes
							live. Your product and price can remain hidden in the eShop during the
							Digital Code ordering process. For more Digital Code order information
							see the <Link to="/orders/digital-codes">Orders/Digital Code Orders</Link>{' '}
							menu.
						</p>
					</div>
				</>
			)}
		</>
	);
};

export default DigitalCatalogTab;
