import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

import ActionLink from '../../components/ActionLink/ActionLink';
import AsperaFileUpload from '../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../components/BaseModal/BaseModal';
import Forms from '../../components/Forms/Forms';
import Page from '../../components/Page/Page';
import Panel from '../../components/Panel/Panel';
import Title from '../../components/Title/Title';
import { allowedFileExtensions } from '../../constants/fileTransferConstants';
import { timeZoneConstants } from '../../constants/timeZoneConstants';
import NotFound from '../../containers/NotFound/NotFound';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getDigitalOrder } from '../../services/digitalCodesService';
import { getProduct } from '../../services/productsService';
import { getUser } from '../../services/usersService';
import { parseDateString } from '../../utils/dateUtils';
import { toasterNotify } from '../../utils/toaster';

const makeNotFoundAPICall = async () => {
	await getUser('undefined');
	toasterNotify('API call was successful, but it was not intended to be successful.', 'warning');
};

const makeBadRequestAPICall = async () => {
	await getProduct('undefined');
	toasterNotify('API call was successful, but it was not intended to be successful.', 'warning');
};

const makeBadAsyncAPICall = async () => {
	await getDigitalOrder(false);
	toasterNotify('API call was successful, but it was not intended to be successful.', 'warning');
};

const Demo = (props) => {
	const userProfile = useUserProfile();
	const [showTestModal, setShowTestModal] = useState(false);
	const [showPaypalModal, setShowPaypalModal] = useState(false);
	const [forceError, setForceError] = useState(false);
	const [fault, setFault] = useState(false);

	const [dates, setDates] = useState({
		select0: '4',
		select1: '3',
		date0: null,
		date1: parseDateString('2022-02-22'),
		date2: '2022-02-22', 
		date3: '2022-02-22T12:00:00Z',
		date4: '2022-02-22',
		date5: '2022-02-22T00:00:00Z',
	});

	if (global.config.authEnv === 'prod1') {
		return <NotFound />;
	}

	const onChange = (dates) => {
		setDates(dates);
	};

	if (forceError) {
		return <div>{null.test('break meeee')}</div>;
	}

	// paypal stuff
	return (
		<>
			<Page fault={fault}>
				<Title
					title="Layout Demo"
					button={
						<>
							<Button onClick={(e) => setShowTestModal(true)}>Open Modal</Button>
							<Button onClick={(e) => setShowPaypalModal(true)}>Open Paypal</Button>
						</>
					}
				/>
				<Page.SplitPage>
					<Page.MainCol>
						<Page.ContentContainer>
							<Alert variant="info">This is an info alert banner</Alert>
							<Alert variant="success">This is an success alert banner</Alert>
							<Alert variant="warning">This is a warning alert banner</Alert>
							<Alert variant="danger">This is a error/danger alert banner</Alert>
						</Page.ContentContainer>
					</Page.MainCol>
					<Page.SidebarCol>
						<Panel>
							<Panel.Heading>
								<Panel.Title>Show Modals</Panel.Title>
							</Panel.Heading>
							<Panel.Body>
								<ActionLink
									onClick={(e) => toasterNotify('This is an info toast', 'info')}
								>
									Info toaster
								</ActionLink>
								<br />
								<ActionLink
									onClick={(e) =>
										toasterNotify('This is a success toast', 'success')
									}
								>
									Success toaster
								</ActionLink>
								<br />
								<ActionLink
									onClick={(e) =>
										toasterNotify('This is a warning toast', 'warning')
									}
								>
									Warning toaster
								</ActionLink>
								<br />
								<ActionLink
									onClick={(e) =>
										toasterNotify('This is a error toast', 'error', {
											response: {
												status: 999,
												headers: { 'x-correlation-id': '12345678' },
											},
										})
									}
								>
									Error toaster
								</ActionLink>
								<br />
							</Panel.Body>
						</Panel>
						<Panel>
							<Panel.Heading>
								<Panel.Title>Break Things</Panel.Title>
							</Panel.Heading>
							<Panel.Body>
								<ActionLink
									onClick={(e) => {
										throw new Error('Demo error');
									}}
								>
									Throw an exception
								</ActionLink>
								<br />
								<ActionLink
									onClick={(e) => {
										setFault(true);
									}}
								>
									Break page
								</ActionLink>
								<br />
								<ActionLink
									onClick={(e) => {
										setForceError(true);
									}}
								>
									Break app
								</ActionLink>
								<br />
								<ActionLink
									onClick={() => makeNotFoundAPICall()}
								>
									Make 404 API call
								</ActionLink>
								<br />
								<ActionLink
									onClick={() => makeBadRequestAPICall()}
								>
									Make Bad Request API call
								</ActionLink>
								<br />
								<ActionLink
									onClick={() => makeBadAsyncAPICall()}
								>
									Make bad async API call (error after polling)	
								</ActionLink>
							</Panel.Body>
						</Panel>
					</Page.SidebarCol>
				</Page.SplitPage>
			</Page>
			<BaseModal show={showTestModal} onCancel={(e) => setShowTestModal(false)}>
				<BaseModal.Title>Test Modal</BaseModal.Title>
				<Forms
					values={dates}
					onChange={(values) =>
						onChange({
							...values,
							text1: values.text1?.toUpperCase(),
							text2: values.text2?.toUpperCase(),
						})
					}
				>
					<Forms.Select id="select0">
						<Forms.Heading>Dropdown</Forms.Heading>
						<Forms.Option value="1">One</Forms.Option>
						<Forms.Option value="2" footnote={'This is like one but with another one'}>Two</Forms.Option>
						<Forms.Option value="3" disabled={true} footnote="Not available">Three</Forms.Option>
						<Forms.Option value="4" footnote="Danger!" variant='danger' disabled={true}>Four</Forms.Option>
						<Forms.Option value="5" footnote="Permissible Danger!" variant='danger' disabled={false}>Five</Forms.Option>
					</Forms.Select>
					<Forms.Select id="select1">
						<Forms.Heading>Dropdown</Forms.Heading>
						<Forms.Option value="1">One</Forms.Option>
						<Forms.Option value="2" footnote={'This is like one but with another one'}>Two</Forms.Option>
						<Forms.Option value="3" disabled={true} footnote="Not available">Three</Forms.Option>
						<Forms.Option value="4" footnote="Danger!" variant='danger' disabled={true}>Four</Forms.Option>
					</Forms.Select>
					<Forms.DateSelect id="date0" disabled>
						<Forms.Heading>Date Select</Forms.Heading>
					</Forms.DateSelect>
					<Forms.DateSelect id="date1">
						<Forms.Heading>Date Select</Forms.Heading>
					</Forms.DateSelect>
					<Forms.DateSelect id="date2">
						<Forms.Heading>Date Select</Forms.Heading>
					</Forms.DateSelect>
					<Forms.DateSelect id="date3" showTimeSelect>
						<Forms.Heading>Date Select</Forms.Heading>
					</Forms.DateSelect>
					<Forms.DateSelect id="date4" timezone={timeZoneConstants.JAPAN}>
						<Forms.Heading>Date Select (JST)</Forms.Heading>
					</Forms.DateSelect>
					<Forms.DateSelect id="date5" timezone={timeZoneConstants.JAPAN} showTimeSelect>
						<Forms.Heading>Date and Time Select (JST)</Forms.Heading>
					</Forms.DateSelect>
					<Forms.Text id="text1">
						<Forms.Heading>All Caps</Forms.Heading>
					</Forms.Text>
					<Forms.TextArea id="text2" rows="4">
						<Forms.Heading>All Caps Multiline</Forms.Heading>
					</Forms.TextArea>
					<Forms.TextArea id="text3" rows="4">
						<Forms.Heading>Ordinary Multiline</Forms.Heading>
					</Forms.TextArea>
					<Forms.CustomArea>
						<Forms.Heading>Select File</Forms.Heading>
						<AsperaFileUpload
							updateFilesSelected={(status) => 0}
							userProfile={userProfile}
							entityType={null}
							entityId={null}
							isUploadInitiated={false}
							onCompleteFunction={() => 0}
							allowedFileTypes={[
								{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.PLAIN_TEXT,
								},
							]}
							validateFileType={(files) => true}
							singleFileOnly
						/>
					</Forms.CustomArea>
				</Forms>
				<ActionLink onClick={(e) => toasterNotify('This is an info toast', 'info')}>
					Info toaster
				</ActionLink>
				<br />
				<ActionLink onClick={(e) => toasterNotify('This is a success toast', 'success')}>
					Success toaster
				</ActionLink>
				<br />
				<ActionLink onClick={(e) => toasterNotify('This is a warning toast', 'warning')}>
					Warning toaster
				</ActionLink>
				<br />
				<ActionLink
					onClick={(e) =>
						toasterNotify('This is a error toast', 'error', {
							response: { status: 999, headers: { 'x-correlation-id': '12345678' } },
						})
					}
				>
					Error toaster
				</ActionLink>
				<br />
			</BaseModal>
			<BaseModal show={showPaypalModal} onCancel={(e) => setShowPaypalModal(false)}>
				<BaseModal.Title>Paypal Test</BaseModal.Title>
				
			</BaseModal>
		</>
	);
};
export default Demo;
