import { ReactElement } from 'react';
import {
	createTable,
	getCoreRowModel,
	RowData,
	TableOptionsResolved,
	CellContext,
	ColumnDef,
	createColumnHelper,
	VisibilityState,
} from '@tanstack/table-core';
import pickBy from 'lodash/pickBy';

const csvjson = require('csvjson');

const columnHelper = createColumnHelper<unknown>();

export const convertToCSV = <TData extends RowData>(
	data: TData[],
	columns: Record<string, any>[],
	filename?: string,
) => {
	const [convertedColumns, columnVisibility] = convertToNewColumnFormat(columns);
	const resolvedOptions: TableOptionsResolved<TData> = {
		state: { columnVisibility }, // Dummy state
		onStateChange: () => {}, // noop
		renderFallbackValue: null,
		getCoreRowModel: getCoreRowModel(),
		data,
		columns: convertedColumns as ColumnDef<TData, any>[],
	};
	const table = createTable<TData>(resolvedOptions);

	const json = table.getRowModel().rows.map((row) =>
		Object.fromEntries(
			row.getAllCells().map((cell) => [
				cell.column.columnDef.header,
				flexRender(cell.column.columnDef.cell, cell.getContext()),
			]),
		),
	);

	const csv = csvjson.toCSV(json, { headers: 'key' });
	const blob = new Blob([csv], { type: 'text/csv' });
	const url = URL.createObjectURL(blob);

	filename =
		filename ??
		document.title.match(/^(.*)( - Nintendo Publisher Tool)$/)?.[1]?.replace(/\s/g, '_') ??
		'data';

	const a = document.createElement('a');
	a.href = url;
	a.download = filename + '.csv';
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};

const convertToNewColumnFormat = (
	columns: Record<string, any>[],
): [ColumnDef<unknown, unknown>[], VisibilityState] => {
	const converted: any[] = [];
	const columnVisibility: VisibilityState = {};
	columns.forEach((original, index) => {
		const id =
			original.id ||
			(typeof original.accessor === 'string' ? original.accessor : `no-id-column-${index}`);
		columnVisibility[id] = original.show !== false;
		if (
			original.accessor &&
			(typeof original.accessor === 'string' || typeof original.accessor === 'function')
		) {
			const newColumn = columnHelper.accessor(
				original.accessor,
				pickBy(
					{
						id,
						header: original.Header,
						cell:
							original.cell ||
							(original.Cell
								? (info: CellContext<unknown, unknown>) => {
									return original.Cell({
										value: info.getValue(),
										original: info.row.original,
										column: info.column.columnDef,
									});
								  }
								: (info: CellContext<unknown, unknown>) => info.getValue() || null),
					},
					(x) => x !== undefined,
				),
			);
			return converted.push(newColumn);
		}
	});
	return [converted as ColumnDef<unknown, unknown>[], columnVisibility as VisibilityState];
};

export const flexRender = <TProps extends object>(comp: any, props: TProps) => {
	if (typeof comp === 'function') {
		return textContent(comp(props));
	}
	return comp;
};

function textContent(element: ReactElement | string): string {
	if (!element) {
		return '';
	}
	if (typeof element === 'string') {
		return element;
	}
	const { children, alt } = element?.props ?? {};
	if (alt && typeof alt === 'string') {
		return alt;
	}
	if (children instanceof Array) {
		return children.map(textContent).join('');
	}
	return textContent(children);
}
