import React, { VFC } from 'react';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
// import { gameConfigFields } from '../../../constants/gameConfigFields';
import { cleanGameConfigurationValue, gameConfigFields } from './GameConfiguration.helpers';

interface GameConfigurationProps {
	gameConfiguration: any;
	systemFamily?: string;
}
const GameConfiguration: VFC<GameConfigurationProps> = ({ gameConfiguration, systemFamily }) => {
	let panelBody: any[] = [];
	if (systemFamily && systemFamily in gameConfigFields) {
		const gameConfigFieldsForPlatform = (gameConfigFields as Record<string, any>)[systemFamily];
		const headerNames = Object.keys(gameConfigFieldsForPlatform);
		headerNames.forEach((headerName) => {
			panelBody.push(
				<SectionTitle key={'game-config-' + headerName}>
					{gameConfigFieldsForPlatform[headerName]['title']}
				</SectionTitle>,
			);
			Object.keys(gameConfigFieldsForPlatform[headerName]['fields']).forEach(
				(gameConfigurationFeature, index) => {
					const apiReturnedFeatureName =
						gameConfigFieldsForPlatform[headerName]['fields'][gameConfigurationFeature];
					const value = cleanGameConfigurationValue(
						gameConfiguration[headerName]
							? gameConfiguration[headerName][apiReturnedFeatureName]
							: '',
					);
					panelBody.push(
						<PropertyDisplay
							label={gameConfigurationFeature}
							key={gameConfigurationFeature + index}
						>
							{value != null ? value.toString() : ''}
						</PropertyDisplay>,
					);
				},
			);
		});
		return <>{panelBody}</>;
	}
	return null;
};

export default GameConfiguration;
